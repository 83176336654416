import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

import Home from '@/views/Home.vue'
// import { component } from 'vue/types/umd';

const Settled = () => import ("../views/Settled.vue");
const SettledDetail = () => import ("../views/SettledDetail.vue");


const information = () => import("../components/information.vue");//商城信息
const index = () => import("../components/index.vue");//概况
const role = () => import("../components/role.vue");//角色管理
const operator = () => import("../components/operator.vue");//操作员管理
const consolog = () => import("../components/consolog.vue");//操作日志
const menu = () => import("../components/menu.vue");//菜单列表
const attachsList = () => import("../components/attachsList.vue");//附件
const annex = () => import("../components/annex.vue");//附件
const applyAttUpload = () => import("../components/applyAttUpload.vue");//附件-添加图片
const AdminInfo = () => import("../components/AdminInfo.vue"); //个人资料
//商户管理
const bussinessRole = () => import("../components/businessRole.vue");//角色管理
const addMerchantRole = () => import("../components/addMerchantRole.vue");//添加角色
const editMerchantRole = () => import("../components/editMerchantRole.vue");//编辑角色
const businessGroup = () => import("../components/businessGroup.vue");//
const buinessAdmin = () => import("../components/buinessAdmin.vue");//
const buinessSet = () => import("../components/buinessSet.vue");//结算概览
const Settlement = () => import("../components/Settlement.vue");//结算概览--申请结算
const manageList = () => import("../components/manageList.vue");//结算概览--结算详情--提交审核按钮
const manageDetail = () => import("../components/manageDetail.vue");//结算概览--结算详情--查看
const businesses = () => import("../components/businesses.vue");//
const other = () => import("../components/other.vue");//其他
const basic = () => import("../components/basics.vue");//基础设置
const finance = () => import("../components/drawal.vue");//提现管理
const adminCar = () => import("../components/adminCar.vue");//车辆管理
const CarParts = () => import("../components/CarParts.vue");//车辆部位
const CarBrand = () => import("../components/CarBrand.vue");//车辆品牌

const CarApply = () => import("../components/CarApply.vue");//车型申请
const CarInfosEdit = () => import("../components/CarInfosEdit.vue");//添加车型
const CarInfosShow = () => import("../components/CarInfosShow.vue");//车型申请详情

const PartApply = () => import("../components/PartApply.vue");//配件申请
const applyPart = () => import("../components/applyPart.vue");//添加配件


const freight = () => import("../components/freight.vue");//配件售卖设置--运费
const deliveryTime = () => import("../components/deliveryTime.vue");//配件售卖设置--发货时间
const label = () => import("../components/label.vue");//配件售卖设置--标签
const logistics = () => import("../components/logistics.vue");//配件售卖设置--物流基础信息
const delivery = () => import("../components/delivery.vue");//发货信息
const receive = () => import("../components/receive.vue");//接单小票
const honor = () => import("../components/honor.vue");//荣誉资质列表
const honorAdd = () => import("../components/honorAdd.vue");//荣誉资质新增
const honorEdit = () => import("../components/honorEdit.vue");//荣誉资质编辑
const logisticsCompany = () => import("../components/logisticsCompany.vue");//配件售卖设置--物流公司信息
const RefundDetail = () => import("../components/RefundDetail.vue");//售后订单详情


// 订单管理
const orderManage = () => import("../components/orderManage.vue");

// 订单详情
const orderDetail = () => import("../components/orderDetail.vue");

// 商品评价
const reviews = () => import("../components/Reviews.vue");

// 商品评价详情
const reviewsDetails = () => import("../components/ReviewsDetails.vue");

// 使用手册
const Manual = () => import("../components/Manual.vue");

// 使用手册详情
const manualDetails = () => import("../components/manualDetails.vue");


// 订单售后--售后列表
const Refund = () => import("../components/Refund.vue");//售后订单列表


// 商品管理
const merchantPartGoods = () => import("../components/merchantPartGoods.vue");
const merchantPartGoodsList = () => import("../components/merchantPartGoodsList.vue");

const ProductGoodsEdit = () => import("../components/ProductGoodsEdit.vue");//商品编辑

// 支付通知
// const orderPay=() =>import("../components/orderPay.vue");

// // 供应商
// const supplier=() =>import("../components/supplier.vue");
// // 下属公司
// const company=() =>import("../components/company.vue");
// // 客户
// const customer=() =>import("../components/customer.vue");
// // 产品
// const product=() =>import("../components/product.vue");
// // 仓库
// const warehouse=() =>import("../components/warehouse.vue");
// // 基础资料
// const basicPosition=() =>import("../components/basicPosition.vue");
// // 财务
// const financial=() =>import("../components/financial.vue");
// // 出入库
// const inventoryChanges=() =>import("../components/inventoryChanges.vue");

// 仓储模块
// 仓储概览
const strindex = () => import("../components/strindex.vue");
// 供应商类型
const supplierType = () => import("../components/supplierType.vue");
// 供应商
const supplierList = () => import("../components/supplierList.vue");
// 供应商编辑
const supplierListAdd = () => import("../components/supplierListAdd.vue");
// 产品类型
const productType = () => import("../components/productType.vue");

// 产品品牌
const productBrand = () => import("../components/productBrand.vue");


// 下属公司
const company = () => import("../components/company.vue");
// 下属公司-添加
const companyAdd = () => import("../components/companyAdd.vue");


// 供应商收款账户
const supplierAccount = () => import("../components/supplierAccount.vue");
// 供应商收款账户 -添加
const supplierAccountAdd = () => import("../components/supplierAccountAdd.vue");
// 供应商收付款
const supplierOrder = () => import("../components/supplierOrder.vue");

// 客户
const customer = () => import("../components/customer.vue");
// 客户 -添加
const customerAdd = () => import("../components/customerAdd.vue");
// 客户类型
const customerType = () => import("../components/customerType.vue");
// 客户收款账户
const customerAccount = () => import("../components/customerAccount.vue");
// 客户收款账户添加
const customerAccountAdd = () => import("../components/customerAccountAdd.vue");
// 客户收付款
const customerOrder = () => import("../components/customerOrder.vue");


// 产品
const product = () => import("../components/product.vue");
// 产品添加
const productAdd = () => import("../components/productAdd.vue");
// 制造商
const productMaker = () => import("../components/productMaker.vue");
// 制造商添加/编辑
const productMakerAdd = () => import("../components/productMakerAdd.vue");
// 制造商类型
const productMakerType = () => import("../components/productMakerType.vue");
// 仓库
const warehouse = () => import("../components/warehouse.vue");
// 仓库添加/编辑
const warehouseAdd = () => import("../components/warehouseAdd.vue");
// 基础资料--库区管理
const basicPosition = () => import("../components/basicPosition.vue");
// 基础资料--库区管理-添加/编辑
const basicPositionAdd = () => import("../components/basicPositionAdd.vue");
// 基础资料--货架管理
const Shelves = () => import("../components/Shelves.vue");
// 基础资料--货架管理-添加/编辑
const ShelvesAdd = () => import("../components/ShelvesAdd.vue");
// 基础资料--货架层级管理
const Layer = () => import("../components/Layer.vue");
// 基础资料--货架层级管理-添加/编辑
const LayerAdd = () => import("../components/LayerAdd.vue");
// 基础资料--货位管理
const position = () => import("../components/position.vue");
// 基础资料--货位管理-添加/编辑
const positionAdd = () => import("../components/positionAdd.vue");
// 财务
const financial = () => import("../components/financial.vue");
// 财务--收款单
const receipt = () => import("../components/receipt.vue");
// 财务--付款单
const PaymentOrder = () => import("../components/PaymentOrder.vue");
// 财务--付款单添加
const PaymentOrderAdd = () => import("../components/PaymentOrderAdd.vue");
// 财务--收款单添加
const receiptAdd = () => import("../components/receiptAdd.vue");
// 财务--商户收/付款账户管理
const payments = () => import("../components/payments.vue");
// 财务--商户收/付款账户管理
const paymentsAdd = () => import("../components/paymentsAdd.vue");
// 对账单--客户对账单
const crBill = () => import("../components/crBill.vue");
// 对账单--供应商对账单
const srBill = () => import("../components/srBill.vue");
// 对账单--出入库对账单
const statement = () => import("../components/statement.vue");


// 应收单列表
const receivableList = () => import("../components/receivableList.vue");
// 应收单编辑
const receivableListAdd = () => import("../components/receivableListAdd.vue");

// 应付单列表
const payableList = () => import("../components/payableList.vue");
// 应付单编辑
const payableListAdd = () => import("../components/payableListAdd.vue");

// 入库申请单列表
const stockInList = () => import("../components/stockInList.vue");
// 入库申请单编辑
const stockInListAdd = () => import("../components/stockInListAdd.vue");

// 收货入库列表
const stockInCheckList = () => import("../components/stockInCheckList.vue");
// 收货入库编辑
const stockInCheck = () => import("../components/stockInCheck.vue");

// 出库申请单列表
const stockOutList = () => import("../components/stockOutList.vue");
// 出库申请单编辑
const stockOutListAdd = () => import("../components/stockOutListAdd.vue");

// 发货入库列表
const stockOutCheckList = () => import("../components/stockOutCheckList.vue");
// 发货入库编辑
const stockOutCheck = () => import("../components/stockOutCheck.vue");

// 移库申请单列表
const stockMoveList = () => import("../components/stockMoveList.vue");
// 移库申请单编辑
const stockMoveListAdd = () => import("../components/stockMoveListAdd.vue");

// 移库作业
const stockMoveCheckList = () => import("../components/stockMoveCheckList.vue");
// 移库确认
const stockMoveCheck = () => import("../components/stockMoveCheck.vue");

// 货品调拨列表
const stockMovesList = () => import("../components/stockMovesList.vue");
// 货品调拨编辑
const stockMovesListAdd = () => import("../components/stockMovesListAdd.vue");

// 货品调拨作业
const stockMovesCheckList = () => import("../components/stockMovesCheckList.vue");
// 货品调拨确认
const stockMovesCheck = () => import("../components/stockMovesCheck.vue");

// 货品调拨列表
const inventoryList = () => import("../components/inventoryList.vue");
// 货品调拨编辑
const inventoryListAdd = () => import("../components/inventoryListAdd.vue");

// 盘点作业
const inventoryCheckList = () => import("../components/inventoryCheckList.vue");
// 盘点确认
const inventoryCheck = () => import("../components/inventoryCheck.vue");

// 产品库存变动日志
const productStockLogList = () => import("../components/productStockLogList.vue");


// 仓储设置
const BasicSettings = () => import("../components/BasicSettings.vue");

// 角色管理
const addRole = () => import("../components/addRole.vue");//添加角色
const editRole = () => import("../components/editRole.vue");//编辑角色
const setUp = () => import("../components/setUp.vue");//提现设置
const editCheck = () => import("../components/editCheck.vue");//提现审核
const setGetDetails = () => import("../components/setGetDetails.vue");//提现详情(查看)

const LogDetail = () => import("../components/LogDetail.vue");

//日志详情

// const BuinessDetail= () =>import("../components/setUp.vue");//编辑角色

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
    },
    {
        path: "/settled",
        name: "Settled",
        component: Settled,
    },
    {
        path: "/settledDetail",
        name: "SettledDetail",
        component: SettledDetail,
    },
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: Home
    // },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        // redirect: "/index",
        children: [
            {
                path: "/home/index",
                name: 'index',
                component: index,
                meta: {
                    title: '概况'
                }
            },
            {
                path: "/home/information",
                name: "information",
                component: information,
                meta: {
                    title: '商城信息'
                }
            },
            {
                path: "/home/menu",
                name: "menu",
                component: menu,
                meta: {
                    title: '菜单列表'
                }
            },
            {
                path: "/home/role",
                name: "role",
                component: role,
                meta: {
                    title: '角色权限'
                }
            },
            {
                path: "/home/operator",
                name: "operator",
                component: operator,
                meta: {
                    title: '操作员管理'
                }
            },
            {
                path: "/home/consolog",
                name: "consolog",
                component: consolog,
                meta: {
                    title: '操作日志'
                }
            },
            {
                path: "/home/annex",
                name: "annex",
                component: annex,
                meta: {
                    title: '附件列表'
                }
            },
            {
                path: "/home/other",
                name: "other",
                component: other,
                meta: {
                    title: '其他'
                }
            },
            {
                path: "/home/basic",
                name: "basic",
                component: basic,
                meta: {
                    title: '基础设置'
                }
            },
            // {
            //   path:"/home/attachsList",
            //   name:"attachsList",
            //   component:attachsList,
            //   meta:{
            //     title:'附件'
            //   }
            // },
            {
                path: "/home/applyAttUpload",
                name: "applyAttUpload",
                component: applyAttUpload,
                meta: {
                    title: '添加图片'
                }
            },
            {
                path: "/home/AdminInfo",
                name: "AdminInfo",
                component: AdminInfo,
                meta: {
                    title: '个人资料'
                }
            },

            // 商户管理
            {
                path: "/home/bussinessRole",
                name: "bussinessRole",
                component: bussinessRole,
                meta: {
                    title: '角色管理'
                }
            },
            // 添加
            {
                path: "/home/addMerchantRole",
                name: "addMerchantRole",
                component: addMerchantRole,
                meta: {
                    title: '添加角色'
                }
            },
            // 编辑
            {
                path: "/home/editMerchantRole",
                name: "editMerchantRole",
                component: editMerchantRole,
                meta: {
                    title: '编辑角色'
                }
            },

            {
                path: "/home/businessGroup",
                name: "businessGroup",
                component: businessGroup,
                meta: {
                    title: '商户分组'
                }
            },
            {
                path: "/home/buinessAdmin",
                name: "buinessAdmin",
                component: buinessAdmin,
                meta: {
                    title: '操作员账户'
                }
            },
            {
                path: "/home/businesses",
                name: "businesses",
                component: businesses,
                meta: {
                    title: '商户列表'
                }
            },
            {
                path: "/home/buinessSet",
                name: "buinessSet",
                component: buinessSet,
                meta: {
                    title: '财务概览'
                }
            },
            {
                path: "/home/Settlement",
                name: "Settlement",
                component: Settlement,
                meta: {
                    title: '结算详情'
                }
            },
            {
                path: "/home/manageList",
                name: "manageList",
                component: manageList,
                meta: {
                    title: '提现记录'
                }
            },
            {
                path: "/home/manageDetail",
                name: "manageDetail",
                component: manageDetail,
                meta: {
                    title: '结算详情'
                }
            },
            {
                path: "/home/setUp",
                name: "setUp",
                component: setUp,
                meta: {
                    title: '设置'
                }
            },
            {
                path: "/home/finance",
                name: "finance",
                component: finance,
                meta: {
                    title: '提现管理'
                }
            },

            {
                path: "/home/editCheck",
                name: "editCheck",
                component: editCheck,
                meta: {
                    title: '提现审核'
                }
            },
            {
                path: "/home/setGetDetails",
                name: "setGetDetails",
                component: setGetDetails,
                meta: {
                    title: '提现详情'
                }
            },

            // {
            //   path:"/home/adminCar",
            //   name:"adminCar",
            //   component:adminCar,
            //   meta:{
            //     title:'车辆管理'
            //   }
            // },
            {
                path: "/home/CarBrand",
                name: "CarBrand",
                component: CarBrand,
                meta: {
                    title: '车辆品牌'
                }
            },
            {
                path: "/home/CarParts",
                name: "CarParts",
                component: CarParts,
                meta: {
                    title: '车辆部位'
                }
            },
            {
                path: "/home/adminCar",
                name: "adminCar",
                component: adminCar,
                meta: {
                    title: '车辆管理'
                }
            },
            {
                path: "/home/addRole",
                name: "addRole",
                component: addRole,
                meta: {
                    title: '添加角色'
                }
            },
            {
                path: "/home/editRole",
                name: "editRole",
                component: editRole,
                meta: {
                    title: '编辑角色'
                }
            },
            {
                path: "/home/LogDetail",
                name: "LogDetail",
                component: LogDetail,
                meta: {
                    title: '日志详情'
                }
            },
            {
                path: "/home/CarApply",
                name: "CarApply",
                component: CarApply,
                meta: {
                    title: '车型申请'
                }
            },
            {
                path: "/home/CarInfosEdit",
                name: "CarInfosEdit",
                component: CarInfosEdit,
                meta: {
                    title: '添加车型'
                }
            },
            {
                path: "/home/CarInfosShow",
                name: "CarInfosShow",
                component: CarInfosShow,
                meta: {
                    title: '车型申请详细'
                }
            },
            {
                path: "/home/PartApply",
                name: "PartApply",
                component: PartApply,
                meta: {
                    title: '配件申请'
                }
            },
            {
                path: "/home/applyPart",
                name: "applyPart",
                component: applyPart,
                meta: {
                    title: '添加配件'
                }
            },
            {
                path: "/home/freight",
                name: "freight",
                component: freight,
                meta: {
                    title: '运费模板'
                }
            },
            {
                path: "/home/deliveryTime",
                name: "deliveryTime",
                component: deliveryTime,
                meta: {
                    title: '发货时间'
                }
            },
            {
                path: "/home/label",
                name: "label",
                component: label,
                meta: {
                    title: '商品标签'
                }
            },

            {
                path: "/home/reviews",
                name: "reviews",
                component: reviews,
                meta: {
                    title: '商品评价'
                }
            },

            {
                path: "/home/Manual",
                name: "Manual",
                component: Manual,
                meta: {
                    title: '图文手册'
                }
            },

            {
                path: "/home/manualDetails",
                name: "manualDetails",
                component: manualDetails,
                meta: {
                    title: '图文手册详情'
                }
            },

            {
                path: "/home/reviewsDetails",
                name: "reviewsDetails",
                component: reviewsDetails,
                meta: {
                    title: '商品评价详情'
                }
            },
            {
                path: "/home/logistics",
                name: "logistics",
                component: logistics,
                meta: {
                    title: '物流面单'
                }
            },
            {
                path: "/home/delivery",
                name: "delivery",
                component: delivery,
                meta: {
                    title: '发货信息'
                }
            },
            {
                path: "/home/receive",
                name: "receive",
                component: receive,
                meta: {
                    title: '接单小票'
                }
            },
            {
                path: "/home/logisticsCompany",
                name: "logisticsCompany",
                component: logisticsCompany,
                meta: {
                    title: '物流公司信息'
                }
            },
            {
                path: "/home/honor",
                name: "honor",
                component: honor,
                meta: {
                    title: '荣誉资质'
                }
            },
            {
                path: "/home/honorAdd",
                name: "honorAdd",
                component: honorAdd,
                meta: {
                    title: '荣誉资质新增'
                }
            },
            {
                path: "/home/honorEdit",
                name: "honorEdit",
                component: honorEdit,
                meta: {
                    title: '荣誉资质编辑'
                }
            },
            {
                path: "/home/orderManage",
                name: "orderManage",
                component: orderManage,
                meta: {
                    title: '订单管理'
                }
            },
            {
                path: "/home/orderDetail",
                name: "orderDetail",
                component: orderDetail,
                meta: {
                    title: '订单详情'
                }
            },

            {
                path: "/home/Refund",
                name: "Refund",
                component: Refund,
                meta: {
                    title: '售后订单'
                }
            },

            {
                path: "/home/RefundDetail",
                name: "RefundDetail",
                component: RefundDetail,
                meta: {
                    title: '订单售后详情'
                }
            },

            {
                path: "/home/merchantPartGoods",
                name: "merchantPartGoods",
                component: merchantPartGoods,
                meta: {
                    title: '商品上架'
                }
            },
            {
                path: "/home/merchantPartGoodsList",
                name: "merchantPartGoodsList",
                component: merchantPartGoodsList,
                meta: {
                    title: '商品管理'
                }
            },
            {
                path: "/home/ProductGoodsEdit",
                name: "ProductGoodsEdit",
                component: ProductGoodsEdit,
                meta: {
                    title: '商品编辑'
                }
            },
            // {
            //   path:"/home/orderPay",
            //   name:"orderPay",
            //   component:orderPay,
            //   meta:{
            //     title:'支付通知'
            //   }
            // },
            // {
            //   path:"/home/supplier",
            //   name:"supplier",
            //   component:supplier,
            //   meta:{
            //     title:'供应商'
            //   }
            // },
            // {
            //   path:"/home/company",
            //   name:"company",
            //   component:company,
            //   meta:{
            //     title:'公司'
            //   }
            // },
            // {
            //   path:"/home/customer",
            //   name:"customer",
            //   component:customer,
            //   meta:{
            //     title:'客户'
            //   }
            // },
            // {
            //   path:"/home/product",
            //   name:"product",
            //   component:product,
            //   meta:{
            //     title:'产品'
            //   }
            // },
            // {
            //   path:"/home/warehouse",
            //   name:"warehouse",
            //   component:warehouse,
            //   meta:{
            //     title:'仓库'
            //   }
            // },
            // {
            //   path:"/home/basicPosition",
            //   name:"basicPosition",
            //   component:basicPosition,
            //   meta:{
            //     title:'基础资料'
            //   }
            // },
            // {
            //   path:"/home/financial",
            //   name:"financial ",
            //   component:financial ,
            //   meta:{
            //     title:'财务'
            //   }
            // },
            // {
            //   path:"/home/inventoryChanges",
            //   name:"inventoryChanges",
            //   component:inventoryChanges,
            //   meta:{
            //     title:'出入库'
            //   }
            // },

            // {
            //   path:"/home/BuinessDetail",
            //   name:"BuinessDetail",
            //   component:BuinessDetail,
            //   meta:{
            //     title:'商户列表详情'
            //   }
            // },
            {
                path: "/home/strindex",
                name: "strindex",
                component: strindex,
                meta: {
                    title: '仓储概览'
                }
            },
            {
                path: "/home/supplierType",
                name: "supplierType",
                component: supplierType,
                meta: {
                    title: '供应商类型'
                }
            },
            {
                path: "/home/supplierList",
                name: "supplierList",
                component: supplierList,
                meta: {
                    title: '供应商管理'
                }
            },
            {
                path: "/home/supplierListAdd",
                name: "supplierListAdd",
                component: supplierListAdd,
                meta: {
                    title: '供应商编辑'
                }
            },
            {
                path: "/home/supplierAccount",
                name: "supplierAccount",
                component: supplierAccount,
                meta: {
                    title: '供应商收款账户'
                }
            },
            {
                path: "/home/supplierAccountAdd",
                name: "supplierAccountAdd",
                component: supplierAccountAdd,
                meta: {
                    title: '供应商收款账户添加/编辑'
                }
            },
            {
                path: "/home/supplierOrder",
                name: "supplierOrder",
                component: supplierOrder,
                meta: {
                    title: '供应商收付款'
                }
            },


            {
                path: "/home/company",
                name: "company",
                component: company,
                meta: {
                    title: '下属公司'
                }
            },
            {
                path: "/home/companyAdd",
                name: "companyAdd",
                component: companyAdd,
                meta: {
                    title: '下属公司添加'
                }
            },


            {
                path: "/home/customerAdd",
                name: "customerAdd",
                component: customerAdd,
                meta: {
                    title: '客户添加'
                }
            },
            {
                path: "/home/customer",
                name: "customer",
                component: customer,
                meta: {
                    title: '客户'
                }
            },
            {
                path: "/home/customerType",
                name: "customerType",
                component: customerType,
                meta: {
                    title: '客户类型'
                }
            },
            {
                path: "/home/customerAccount",
                name: "customerAccount",
                component: customerAccount,
                meta: {
                    title: '客户收付款'
                }
            },
            {
                path: "/home/customerAccountAdd",
                name: "customerAccountAdd",
                component: customerAccountAdd,
                meta: {
                    title: '客户收款账户添加'
                }
            },
            {
                path: "/home/customerOrder",
                name: "customerOrder",
                component: customerOrder,
                meta: {
                    title: '客户收款账户'
                }
            },


            {
                path: "/home/productType",
                name: "productType",
                component: productType,
                meta: {
                    title: '产品类型'
                }
            },
            {
                path: "/home/productBrand",
                name: "productBrand",
                component: productBrand,
                meta: {
                    title: '产品品牌'
                }
            },
            {
                path: "/home/product",
                name: "product",
                component: product,
                meta: {
                    title: '产品'
                }
            },
            {
                path: "/home/productAdd",
                name: "productAdd",
                component: productAdd,
                meta: {
                    title: '产品添加'
                }
            },

            {
                path: "/home/productMaker",
                name: "productMaker",
                component: productMaker,
                meta: {
                    title: '制造商'
                }
            },
            {
                path: "/home/productMakerAdd",
                name: "productMakerAdd",
                component: productMakerAdd,
                meta: {
                    title: '制造商添加/编辑'
                }
            },
            {
                path: "/home/productMakerType",
                name: "productMakerType",
                component: productMakerType,
                meta: {
                    title: '制造商类型'
                }
            },

            {
                path: "/home/warehouse",
                name: "warehouse",
                component: warehouse,
                meta: {
                    title: '仓库'
                }
            },
            {
                path: "/home/warehouseAdd",
                name: "warehouseAdd",
                component: warehouseAdd,
                meta: {
                    title: '仓库添加/编辑'
                }
            },
            {
                path: "/home/basicPosition",
                name: "basicPosition",
                component: basicPosition,
                meta: {
                    title: '库区管理'
                }
            },
            {
                path: "/home/basicPositionAdd",
                name: "basicPositionAdd",
                component: basicPositionAdd,
                meta: {
                    title: '库区添加/编辑'
                }
            },
            {
                path: "/home/Shelves",
                name: "Shelves",
                component: Shelves,
                meta: {
                    title: '货架管理'
                }
            },
            {
                path: "/home/ShelvesAdd",
                name: "ShelvesAdd",
                component: ShelvesAdd,
                meta: {
                    title: '货架添加/编辑'
                }
            },
            {
                path: "/home/Layer",
                name: "Layer",
                component: Layer,
                meta: {
                    title: '货架层级管理'
                }
            },
            {
                path: "/home/LayerAdd",
                name: "LayerAdd",
                component: LayerAdd,
                meta: {
                    title: '货架层级添加/编辑'
                }
            },
            {
                path: "/home/position",
                name: "position",
                component: position,
                meta: {
                    title: '货位管理'
                }
            },
            {
                path: "/home/positionAdd",
                name: "positionAdd",
                component: positionAdd,
                meta: {
                    title: '货位添加/编辑'
                }
            },

            {
                path: "/home/financial",
                name: "financial ",
                component: financial,
                meta: {
                    title: '财务'
                }
            },
            {
                path: "/home/receipt",
                name: "receipt ",
                component: receipt,
                meta: {
                    title: '收款单'
                }
            },
            {
                path: "/home/PaymentOrder",
                name: "PaymentOrder ",
                component: PaymentOrder,
                meta: {
                    title: '付款单'
                }
            },
            {
                path: "/home/PaymentOrderAdd",
                name: "PaymentOrderAdd",
                component: PaymentOrderAdd,
                meta: {
                    title: '付款单添加'
                }
            },
            {
                path: "/home/receiptAdd",
                name: "receiptAdd",
                component: receiptAdd,
                meta: {
                    title: '收款单添加'
                }
            },
            {
                path: "/home/payments",
                name: "payments",
                component: payments,
                meta: {
                    title: '商户收/付款账户管理'
                }
            },
            {
                path: "/home/paymentsAdd",
                name: "paymentsAdd",
                component: paymentsAdd,
                meta: {
                    title: '商户收/付款账户添加'
                }
            },
            {
                path: "/home/crBill",
                name: "crBill",
                component: crBill,
                meta: {
                    title: '客户对账单'
                }
            },
            {
                path: "/home/srBill",
                name: "srBill",
                component: srBill,
                meta: {
                    title: '供应商对账单'
                }
            },
            {
                path: "/home/statement",
                name: "statement",
                component: statement,
                meta: {
                    title: '出入库对账单'
                }
            },


            {
                path: "/home/receivableList",
                name: "receivableList",
                component: receivableList,
                meta: {
                    title: '应收单列表'
                }
            },
            {
                path: "/home/receivableListAdd",
                name: "receivableListAdd",
                component: receivableListAdd,
                meta: {
                    title: '应收单编辑'
                }
            },
            {
                path: "/home/payableList",
                name: "payableList",
                component: payableList,
                meta: {
                    title: '应付单列表'
                }
            },
            {
                path: "/home/payableListAdd",
                name: "payableListAdd",
                component: payableListAdd,
                meta: {
                    title: '应付单编辑'
                }
            },
            {
                path: "/home/stockInList",
                name: "stockInList",
                component: stockInList,
                meta: {
                    title: '入库申请单列表'
                }
            },
            {
                path: "/home/stockInListAdd",
                name: "stockInListAdd",
                component: stockInListAdd,
                meta: {
                    title: '入库申请单编辑'
                }
            },
            {
                path: "/home/stockInCheckList",
                name: "stockInCheckList",
                component: stockInCheckList,
                meta: {
                    title: '收货入库'
                }
            },
            {
                path: "/home/stockInCheck",
                name: "stockInCheck",
                component: stockInCheck,
                meta: {
                    title: '收货入库编辑'
                }
            },

            {
                path: "/home/stockOutList",
                name: "stockOutList",
                component: stockOutList,
                meta: {
                    title: '出库申请单列表'
                }
            },
            {
                path: "/home/stockOutListAdd",
                name: "stockOutListAdd",
                component: stockOutListAdd,
                meta: {
                    title: '出库申请单编辑'
                }
            },

            {
                path: "/home/stockOutCheckList",
                name: "stockOutCheckList",
                component: stockOutCheckList,
                meta: {
                    title: '发货出库'
                }
            },
            {
                path: "/home/stockOutCheck",
                name: "stockOutCheck",
                component: stockOutCheck,
                meta: {
                    title: '发货出库编辑'
                }
            },

            {
                path: "/home/stockMoveList",
                name: "stockMoveList",
                component: stockMoveList,
                meta: {
                    title: '移库申请单列表'
                }
            },
            {
                path: "/home/stockMoveListAdd",
                name: "stockMoveListAdd",
                component: stockMoveListAdd,
                meta: {
                    title: '移库申请单编辑'
                }
            },
            {
                path: "/home/stockMoveCheckList",
                name: "stockMoveCheckList",
                component: stockMoveCheckList,
                meta: {
                    title: '移库作业'
                }
            },
            {
                path: "/home/stockMoveCheck",
                name: "stockMoveCheck",
                component: stockMoveCheck,
                meta: {
                    title: '移库确认'
                }
            },
            {
                path: "/home/stockMovesList",
                name: "stockMovesList",
                component: stockMovesList,
                meta: {
                    title: '货品调拨'
                }
            },
            {
                path: "/home/stockMovesListAdd",
                name: "stockMovesListAdd",
                component: stockMovesListAdd,
                meta: {
                    title: '货品调拨编辑'
                }
            },

            {
                path: "/home/stockMovesCheckList",
                name: "stockMovesCheckList",
                component: stockMovesCheckList,
                meta: {
                    title: '货品调拨作业'
                }
            },
            {
                path: "/home/stockMovesCheck",
                name: "stockMovesCheck",
                component: stockMovesCheck,
                meta: {
                    title: '货品调拨确认'
                }
            },
            {
                path: "/home/inventoryList",
                name: "inventoryList",
                component: inventoryList,
                meta: {
                    title: '库存盘点'
                }
            },
            {
                path: "/home/inventoryListAdd",
                name: "inventoryListAdd",
                component: inventoryListAdd,
                meta: {
                    title: '库存盘点编辑'
                }
            },

            {
                path: "/home/inventoryCheckList",
                name: "inventoryCheckList",
                component: inventoryCheckList,
                meta: {
                    title: '盘点作业'
                }
            },
            {
                path: "/home/inventoryCheck",
                name: "inventoryCheck",
                component: inventoryCheck,
                meta: {
                    title: '盘点确认'
                }
            },

            {
                path: "/home/productStockLogList",
                name: "productStockLogList",
                component: productStockLogList,
                meta: {
                    title: '产品库存记录'
                }
            },

            {
                path: "/home/BasicSettings",
                name: "BasicSettings",
                component: BasicSettings,
                meta: {
                    title: '仓储设置'
                }
            },
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
// 定义一个变量来存储上一个路由
Vue.prototype.$lastRouteSign = null;

router.beforeEach((to, from, next) => {
    // 在每次路由跳转前，记录上一个路由
    Vue.prototype.$lastRouteSign = from;
    next()
})
export default router