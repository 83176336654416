<template>
  <div class="box">
    <!-- <div class="box" :style="{ backgroundImage: 'url(' + login_show_img + ')' }"> -->

    <div class="main">
      <!-- 左边 -->
      <div class="box_left" :style="{
        backgroundImage: 'url(' + login_show_img + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"></div>
      <div class="box_right">
        <div class="right_mian" v-if="showPhone">
          <div class="right_header">
            <h3>
              <img :src="chejihui_logo" alt="" class="logo" />
              <div class="sjhdl">手机号登录</div>
            </h3>
          </div>
          <div class="right_form" :rules="rules">
            <div class="phone">
              <div class="xtb">
                <img src="../assets/shouji.png" alt="" />
              </div>
              <input placeholder="请输入手机号" v-model="phoneTimeData.username" />
            </div>
            <div class="yan">
              <div class="yzm">
                <img src="../assets/Vcode.png" alt="" />
              </div>
              <input placeholder="请输入验证码" name="input" v-model="phoneTimeData.code" />
              <button class="ycode picture" :disabled="dislogin">
                <div style="width: 100%; font-weight: bolder; height: 100%" @click="changeImg">
                  <img class="verifyImg" :src="verifyImg" alt="" />
                </div>
              </button>
            </div>
            <!-- 短信验证码 -->
            <!-- 左侧短信图标 -->
            <div class="yan">
              <div class="dxtb">
                <img src="../assets/Vcode.png" alt="" />
              </div>
              <input placeholder="请输入短信验证码" name="input" v-model="phoneTimeData.password" />
              <button class="ycode picture" :disabled="dislogin" @click="getCode1">
                {{ msgPhone }}
              </button>
            </div>
            <div class="forget">
              <div>
                <el-checkbox text-color="#666666" v-model="checked"></el-checkbox>
                <span>
                  记住账号
                </span>
              </div>
              <span @click="code">忘记账号？</span>
            </div>

            <div>
              <el-button class="btn" @click="phoneLogin()">登录</el-button>
            </div>
            <div class="check2">
              <el-checkbox text-color="#666666" v-model="checked2">
              </el-checkbox>
              我已阅读并接受
              <span>《用户使用协议》</span>
            </div>
            <!-- 线 -->
            <!-- 更多登录方式 -->
            <!-- <div class="more">
              <span class="morezi">更多登录方式</span>
            </div> -->
            <el-divider>更多登录方式</el-divider>
            <!-- 下方登录方式 -->
            <div style="display: flex; justify-content: space-between" class="iconSize">
              <!-- 第一组 -->
              <div style="display: flex" @click="accountNumber" class="cursor">
                <img class="login-left-icon" src="../assets/tu1.png" alt="" />
                <div style="line-height: 27px; margin-left: 10px" class="icontu">
                  账号登录
                </div>
              </div>

              <div style="display: flex" @click="ScanCode" class="cursor">
                <img class="login-left-icon" src="../assets/tu2.png" alt="" />
                <div style="line-height: 27px; margin-left: 10px" class="icontu">
                  扫码登录
                </div>
              </div>
              <div style="line-height: 25px" class="icontu">
                还没有注册？
                <span style="color: #0059ff" @click="liRegister()" class="cursor">立即注册</span>
              </div>
            </div>
            <!--            &lt;!&ndash; 版权 &ndash;&gt;-->
            <!--            <div style="text-align: center;">-->
            <!--              Copyright © 2024 青岛茂庭科技有限公司-->
            <!--            </div>-->
          </div>
        </div>
        <div class="right_mian" v-if="showAccount">
          <div class="right_header">
            <h3>
              <img :src="chejihui_logo" alt="" class="logo" />
              <div class="sjhdl">账号登录</div>
            </h3>
          </div>
          <div class="right_form" :rules="rules">
            <div class="phone">
              <div class="xtb">
                <img src="../assets/zhanghao.png" alt="" />
              </div>
              <input placeholder="请输入账号" v-model="AccountTimeData.nickname" />
            </div>
            <div class="phone phone-password" >
              <div class="xtb">
                <img src="../assets/mima.png" alt="" />
              </div>
              <input placeholder="请输入密码" type="password" v-model="AccountTimeData.password" />
            </div>
            <!-- 密码登录图形验证码 -->
            <div class="yan">
              <div class="yzm">
                <img src="../assets/Vcode.png" alt="" />
              </div>
              <input placeholder="请输入验证码" name="input" v-model="AccountTimeData.code" />
              <button class="ycode picture" :disabled="dislogin">
                <div style="width: 100%; font-weight: bolder; height: 100%" @click="changeAccountImg">
                  <img class="verifyImg" :src="verifyImgAccount" alt="" />
                </div>
              </button>
            </div>
            <div class="forget">
              <div>
                <el-checkbox text-color="#666666" v-model="checked"></el-checkbox>
                <span>
                  记住账号
                </span>
              </div>
              <span @click="code">忘记账号？</span>
            </div>
            <div>
              <el-button class="btn" @click="AccountLogin()">登录</el-button>
            </div>
            <div class="check2">
              <el-checkbox text-color="#666666" v-model="checked2">
              </el-checkbox>
              我已阅读并接受
              <span>《用户使用协议》</span>
            </div>
            <!-- 线 -->
            <!-- 更多登录方式 -->
            <!-- <div class="more">
              <span class="morezi">更多登录方式</span>
            </div> -->
            <el-divider>更多登录方式</el-divider>
            <!-- 下方登录方式 -->
            <div style="display: flex; justify-content: space-between" class="iconSize">
              <div style="display: flex" @click="mobilePhone" class="cursor">
                <img class="login-left-icon" src="../assets/phone.png" alt="" />
                <div style="line-height: 27px; margin-left: 10px" class="icontu">手机号登录</div>
              </div>
              <div style="display: flex" @click="ScanCode" class="cursor">
                <img class="login-left-icon" src="../assets/tu2.png" alt="" />
                <div style="line-height: 27px; margin-left: 10px" class="icontu">
                  扫码登录
                </div>
              </div>
              <div style="line-height: 1px; line-height: 25px" class="icontu">
                还没有注册？
                <span style="color: #0059ff" @click="liRegister()" class="cursor">立即注册</span>
              </div>
            </div>
            <!--            &lt;!&ndash; 版权 &ndash;&gt;-->
            <!--            <div style="text-align: center;">-->
            <!--              Copyright © 2024 青岛茂庭科技有限公司-->
            <!--            </div>-->
          </div>
          <!-- <div class="codeImg" v-if="show1">
            <h3 v-if="show1" @click="turnOff">x</h3>
            <img src="../assets/code.jpg" alt="" v-if="show1" />
          </div> -->
          <!-- <div
            style="color: white; text-align: center; margin-top: 20px"
            v-if="show1"
          >
            微信扫一扫，添加客服
          </div> -->
        </div>
        <div class="right_mian" v-if="appShow">
          <div class="right_header">
            <div class="saoma">
              <img :src="chejihui_logo" alt="" class="logo" />
              <div class="sjhdl sjhd2">扫码登录</div>
            </div>
          </div>
          <div class="right_form" :rules="rules">
            <div class="codeImg">
              <div class="titleClose" v-if="appMa">
                <span>车机会APP扫码</span>
                <span @click="closeApp">×</span>
              </div>
              <!--              style="-->
              <!--              width: 228px;-->
              <!--              display: flex;-->
              <!--              justify-content: space-between;-->
              <!--              "-->
              <div class="titleClose" v-if="weiMa">
                <span>微信扫码</span>
                <span @click="closeWin">×</span>
              </div>
              <!-- <img src="../assets/code.jpg" alt=""><img/> -->
              <div class="bj">
                <div class="sao">
                  <div class="appcode" @click="saoApp">
                    <span>APP扫码</span>
                  </div>
                  <div class="weicode" @click="saoWei">
                    <span>微信扫码</span>
                  </div>
                </div>
              </div>

              <!-- app二维码状态2 -->
              <!-- <img :src= ewmImgApp alt="" v-if="appMa" class="codeIMG"> -->
              <!-- 微信二维码状态1 -->
              <img :src="ewmImgwx" alt="" v-if="weiMa" class="codeIMG appimg" />
              <!-- <img src="../assets/code.jpg" alt="" v-if="weiMa" class="codeIMG"> -->
              <canvas id="qrcode" ref="qrcode" title="扫描二维码" v-if="appMa" class="codeIMG">
              </canvas>
              <div class="opacitybj" v-if="showRefresh">
                <img src="../assets/Group.png" alt="" @click="rotate" :class="{ rotate: isRotating }" />
                <div>二维码失效，点击刷新</div>
              </div>
              <!-- <span v-if="CodeStatus == 2">已扫码</span> -->
            </div>
            <span class="ScannedCode" v-if="CodeStatus == 2 && showRefresh == false">已扫码</span>
            <span class="ScannedCode" v-if="CodeStatus == 3">扫码成功</span>

            <div class="check2" style="margin-top: 55px">
              <el-checkbox text-color="#666666" v-model="checked2">
              </el-checkbox>
              我已阅读并接受
              <span>《用户使用协议》</span>
            </div>
            <!-- 线 -->
            <!-- 更多登录方式 -->
            <!-- <div class="more">
              <span class="morezi">更多登录方式</span>
            </div> -->
            <el-divider>更多登录方式</el-divider>
            <!-- 下方登录方式 -->
            <div style="display: flex; justify-content: space-between" class="iconSize">
              <!-- 第一组 -->
              <div style="display: flex" @click="accountNumber" class="cursor">
                <img class="login-left-icon" src="../assets/tu1.png" alt="" />
                <div style="line-height: 27px; margin-left: 10px" class="icontu">
                  账号登录
                </div>
              </div>
              <div style="display: flex" @click="mobilePhone" class="cursor">
                <img class="login-left-icon" src="../assets/phone.png" alt="" />
                <div style="line-height: 27px; margin-left: 10px" class="icontu">
                  手机号登录
                </div>
              </div>

              <div style="line-height: 1px; line-height: 25px" class="icontu">
                还没有注册？
                <span style="color: #0059ff" @click="liRegister()" class="cursor">立即注册</span>
              </div>
            </div>
            <!--            &lt;!&ndash; 版权 &ndash;&gt;-->
            <!--            <div style="text-align: center;">-->
            <!--              Copyright © 2024 青岛茂庭科技有限公司-->
            <!--            </div>-->
          </div>
        </div>
        <div class="right_mian" v-if="showzhu">
          <div class="right_header">
            <h3>
              <img :src="chejihui_logo" alt="" class="logo" />
              <div class="sjhdl">商家注册</div>
            </h3>
          </div>
          <div class="right_form" :rules="rules">
            <div class="phone">
              <div class="xtb">
                <img src="../assets/account.png" alt="" />
              </div>
              <input placeholder="请输入手机号" v-model="setTimerData.phone" />
            </div>
            <!-- 图片验证码 -->
            <div class="yan">
              <div class="yzm">
                <img src="../assets/imgcode.png" alt="" />
              </div>
              <input placeholder="请输入验证码" name="input" v-model="setTimerData.code" />
              <button class="ycode picture" :disabled="dis">
                <div style="width: 100%; font-weight: bolder; height: 100%" @click="RegisterCode">
                  <img class="verifyImg" :src="verifyImgZhu" alt="" />
                </div>
              </button>
            </div>
            <!-- 短信验证码 -->
            <!-- 左侧短信图标 -->
            <div class="yan">
              <div class="dxtb">
                <img src="../assets/text-message.png" alt="" />
              </div>
              <input placeholder="请输入短信验证码" name="input" v-model="registerData.code" />
              <button class="ycode picture" :disabled="dis" @click="getCode">
                {{ msg }}
              </button>
            </div>
            <div class="forget">
              <div>
              <el-checkbox text-color="#666666" v-model="checked"></el-checkbox>
              <span>
                  记住账号
                </span>
              </div>
            </div>
            <div>
              <el-button class="btn" @click="Register()">立即注册</el-button>
            </div>
            <div style="
                display: flex;
                justify-content: center;

              ">
              <div class="yiyou">
                已有账号？
                <span style="color: #0059ff" @click="liLogin" class="cursor">立即登录</span>
              </div>
            </div>
            <div class="check21 checkzi1">
              <el-checkbox text-color="#666666" v-model="checkedRegister">
              </el-checkbox>
              我已阅读并接受
              <span>《用户使用协议》</span>
            </div>
            <!--            &lt;!&ndash; 版权 &ndash;&gt;-->
            <!--            <div style="text-align: center;">-->
            <!--              Copyright © 2024 青岛茂庭科技有限公司-->
            <!--            </div>-->
          </div>
        </div>
        <!-- 版权 -->
        <div style="" class="copyright">
          Copyright © 2024 青岛茂庭科技有限公司
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 引入封装好的api.js
import { apiServe } from "@/api/api";
// 扫码登录二维码
import QRcode from "qrcode";
// 引入外部样式

export default {
  data() {
    return {
      formattedTime: "",
      end_time: "",
      getLoginCodeStatusRes: false,
      loginForm: {
        username: "",
        password: "",
      },
      registerData: {
        phone: "",
        type: 1,
        code: "",
      },
      //手机号登录
      showPhone: true,
      // //账号登录
      showAccount: false,
      // // app二维码显示
      appShow: false,
      //注册
      showzhu: false,
      // 勾选用户使用协议
      readFlag: false,
      type: 1,

      //注册验证码图片
      verifyImgZhu: "",
      //账号密码验证码图片
      verifyImgAccount: "",
      checkedRegister: false,
      // smShow1: false,
      // smShow: true,
      // // 登录按钮
      // denglu: true,
      // // 账号输入框
      // zhanghao: true,
      show: true,
      show1: false,
      dis: false,
      dislogin: false,
      verifyImg: "",
      name: "",
      logo: "",
      login_show_img: "https://maoting.oss-cn-qingdao.aliyuncs.com/bigimgs/logintotheleft.jpg",
      msg: "获取验证码",
      msgPhone: "获取验证码",

      // 扫码登录的点击显示
      codeEwm: true,
      codeEwm1: false,
      currCodeType: 0,
      //app二维码图片
      ewmImgApp: null,
      //微信二维码图片
      ewmImgwx: "",
      appkey: "",
      // 扫码状态
      CodeStatus: "",
      // 年月日
      dateString: "",
      // 显示失效刷新页面
      showRefresh: false,
      // 短信验证码
      setTimerData: {
        uniqid: "",
        code: "",
        phone: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          // {
          //     pattern: /[a-z]{5,10}/g,
          //     message: '5-10位字母',
          //     trigger: 'blur'
          // }
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
          {
            pattern: /^\d{6}$/,
            message: "6位数字",
            trigger: "blur",
          },
        ],
      },
      check_register: "",
      // 记住账号
      checked: true,
      // 记住账号--账号登录
      checkedAccount: true,
      // 我已阅读
      checked2: true,
      appMa: false,
      weiMa: false,
      showIMG: true,
      isRotating: false, // 控制旋转状态的变量
      phoneTimeData: {
        uniqid: "",
        code: "",
        username: "",
      },
      // 账号密码登录
      AccountTimeData: {
        nickname: "",
        password: "",
        uniqid: "",
        code: "",
      },
      chejihui_logo: require("@/assets/chejihui_logo.png"),
    };
  },
  methods: {
    rotate() {
      // 触发旋转
      this.isRotating = true;
      clearInterval(this.StatusTimer);
      setTimeout(() => {
        this.isRotating = false;
        // 这里可以添加其他逻辑，比如发起一个刷新请求
      }, 500); // 假设动画时长为0.5秒
      this.showRefresh = false;
      if (this.currCodeType === 1) {
        // 刷新微信二维码
        this.getLogoEwm();
      } else if (this.currCodeType === 2) {
        // 刷新app二维码
        this.getLogoEwm2();
      }

      this.$message({
        message: "刷新成功",
        type: "success",
      });
    },

    // 登录二维码显示
    getQRCode(coreTxt) {
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 0, //二维码留白边距
        width: 228, //宽
        height: 228, //高
        text: coreTxt, //二维码内容
        color: {
          light: "#fff", //背景色
        },
      };
      QRcode.toCanvas(this.$refs.qrcode, opts.text, opts, function (error) {
        if (error) {
          console.log("加载失败！", error);
        }
      });

      // let myCanvas = this.$refs.qrcode;
      // let ctx = myCanvas.getContext('2d')
      // // 在Canvas画布 添加图片
      // let img = new Image()
      // img.crossOrigin = 'Anonymous'; //解决Canvas.toDataURL 图片跨域问题
      // // img.setAttribute('crossOrigin', 'anonymous'); +'?time=' + new Date().valueOf()
      // img.src ="https://test1.chejiguanjia.com//storage/home/20241030/e397c221cd94eb42c9387b5db2dedc571240.png";
      // img.onload = () => {
      // //第一个设置的元素，第二三是位置，后面两个是宽和高
      // //居中的位置计算为 （二维码宽度-img宽度）/2
      //   let codeWidth = (200 * 0.75) / 2
      //   let codeHeight = (200 * 0.75) / 2
      //   ctx.drawImage(img, codeWidth, codeHeight, 200 * 0.25, 200 * 0.25)
      // }
    },
    // 账号密码登录
    AccountLogin() {
      if (this.checked2 == false) {
        this.$message({
          message: "请勾选用户使用协议!",
          type: "error",
        });
      } else {
        apiServe
          .submitLogin({
            nickname: this.AccountTimeData.nickname,
            password: this.AccountTimeData.password,
          })
          .then((res) => {
            console.log(res, "登录111");

            if (res.error == 0) {
              localStorage.setItem("sign_login", 1);
              // localStorage.setItem("name", res.user.username);
              //只有当选中记住密码才把账号存储起来
              if (this.checkedAccount == true) {
                localStorage.setItem("nameAccount", res.user.nickname);
                // console.log(111, '1111');
              } else {
                localStorage.setItem("nameAccount", "");
              }
              //当点击记住密码后,记住密码单选框变成true,此时把选中的状态传给首页
              this.$router.push("/home/index");
            }
          });
      }
    },
    //手机号登录
    phoneLogin() {
      if (this.checked2 == false) {
        this.$message({
          message: "请勾选用户使用协议!",
          type: "error",
        });
      } else {
        apiServe
          .post("merchantAccount/login/submit", {
            password: this.phoneTimeData.password,
            username: this.phoneTimeData.username,
          })
          .then((res) => {
            console.log(res, "登录111");

            if (res.error == 0) {
              localStorage.setItem("sign_login", 1);
              // localStorage.setItem("name", res.user.username);
              //只有当选中记住密码才把账号存储起来
              if (this.checked == true) {
                localStorage.setItem("name", res.user.username);
                // console.log(111, '1111');
              } else {
                localStorage.setItem("name", "");
              }
              //当点击记住密码后,记住密码单选框变成true,此时把选中的状态传给首页
              this.$router.push("/home/index");
            }
          });
      }
    },
    //手机登录获取手机验证码
    getCode1() {
      if (this.msgPhone !== "获取验证码") {
        this.$message({
          message: "请稍后！",
          type: "info",
        });
      } else {
        setTimeout(() => {
          this.setTimerLogin();
        }, 100);
      }
    },
    //定义一个定时器 用来存放倒计时
    TimerLogin() { },
    setTimerLogin() {
      apiServe
        .post("merchantAccount/login/getCodeSms", {
          uniqid: this.phoneTimeData.uniqid,
          code: this.phoneTimeData.code,
          username: this.phoneTimeData.username,
        })
        .then((res) => {
          if (res.error === 0) {
            this.$message({
              message: "短信验证码发送成功！",
              type: "success",
            });
            let holdTimeLogin = 60;
            this.TimerLogin = setInterval(() => {
              if (holdTimeLogin <= 0) {
                this.dislogin = false;
                this.msgPhone = "获取验证码";
                clearInterval(this.TimerLogin);
                return;
              }
              holdTimeLogin--;
              this.msgPhone = holdTimeLogin;
            }, 1000);
            console.log(res, "短信验证码");
          }
        });
    },

    //立即注册
    Register() {
      if (this.checkedRegister == false) {
        this.$message({
          message: "请勾选用户使用协议!",
          type: "error",
        });
      } else {
        apiServe
          .post("merchantAccount/register/shopRegister", {
            type: 1,
            code: this.registerData.code,
            phone: this.setTimerData.phone,
          })
          .then((res) => {
            console.log(res, "注册");
            if (res.error == 0) {
              this.$message({
                message: "注册成功!",
                type: "success",
              });
              this.checked2 = true;
              setTimeout(() => {
                console.log("注册成功");
                this.showzhu = false;
                this.showPhone = true;
              }, 1000);
            }
          });
      }
    },
    closeApp() {
      this.CodeStatus = null;
      this.appMa = false;
      this.showRefresh = false;
      clearInterval(this.StatusTimer);
    },
    closeWin() {
      this.CodeStatus = null;
      this.weiMa = false;
      this.showRefresh = false;
      clearInterval(this.StatusTimer);
    },
    saoApp() {
      this.appMa = true;
      this.weiMa = false;
      // 二维码登录显示
      this.getLogoEwm2();
      // 轮询 扫码状态
      // this.clear();
      // this.getLoginCodeStatus();
    },
    saoWei() {
      this.appMa = false;
      this.weiMa = true;
      this.getLogoEwm();
    },

    // 点击账号登录
    accountNumber() {
      //微信 手机号隐藏
      this.showAccount = true;
      this.showPhone = false;
      this.appShow = false;
      this.showzhu = false;
      //
      //二维码框回到初始状态
      this.appMa = false;
      this.weiMa = false;
      // this.appMa = false
      this.CodeStatus = null;
      this.showRefresh = false;
      clearInterval(this.StatusTimer);
    },
    // 点击手机号登录
    mobilePhone() {
      //微信 账号隐藏
      this.showPhone = true;
      this.showAccount = false;
      this.appShow = false;
      this.showzhu = false;
      //
      // console.log(this.CodeStatus, 'CodeStatus');
      //二维码框回到初始状态
      this.weiMa = false;
      this.appMa = false;
      this.CodeStatus = null;
      this.showRefresh = false;
      clearInterval(this.StatusTimer);
    },
    // 点击扫码登录
    ScanCode() {
      //微信 账号隐藏
      this.appShow = true;
      this.showPhone = false;
      this.showAccount = false;
      this.showzhu = false;
      this.showRefresh = false;
      clearInterval(this.StatusTimer);
    },
    //点击注册
    liRegister() {
      this.showzhu = true;
      this.appShow = false;
      this.showPhone = false;
      this.showAccount = false;

      //二维码框回到初始状态
      this.weiMa = false;
      this.appMa = false;
      this.CodeStatus = null;
      this.showRefresh = false;
      clearInterval(this.StatusTimer);
    },

    //点击登录
    liLogin() {
      this.showPhone = true;
      this.showzhu = false;
      this.appShow = false;
      this.showAccount = false;
    },

    //定义一个定时器 用来存放倒计时
    Timer() { },
    code() {
      this.show = !this.show;
      this.show1 = !this.show1;
    },
    turnOff() {
      this.show = !this.show;
      this.show1 = !this.show1;
    },

    // 扫码登录，显示整个二维码

    appear() {
      this.codeEwm = !this.codeEwm;
      this.codeEwm1 = !this.codeEwm1;
    },

    // 登录基础信息
    // loginShopInit(){
    //    let params={
    //     name:this.loginForm.name
    //    }
    // },
    //注册验证码
    getCode() {
      if (this.msg !== "获取验证码") {
        this.$message({
          message: "请稍后！",
          type: "info",
        });
      } else {
        setTimeout(() => {
          this.setTimer();
        }, 100);
      }
    },
    setTimer() {
      apiServe
        .post("merchantAccount/register/getRegisterCodeSms", {
          uniqid: this.setTimerData.uniqid,
          code: this.setTimerData.code,
          phone: this.setTimerData.phone,
        })
        .then((res) => {
          if (res.error === 0) {
            this.$message({
              message: "短信验证码发送成功！",
              type: "success",
            });
            let holdTime = 60;
            this.Timer = setInterval(() => {
              if (holdTime <= 0) {
                this.dis = false;
                this.msg = "获取验证码";
                clearInterval(this.Timer);
                return;
              }
              holdTime--;
              this.msg = holdTime;
            }, 1000);
            console.log(res, "短信验证码");
          }
        });
    },
    //     submitForm() {
    //     // this.$refs[formName].validate(async (valid) => {
    //     // if (!valid) return false;
    //     // const { data: res } = await this.axios.post("login", this.loginForm)
    //     // console.log(res)
    //     // if (res.code == 101) return this.$message.error('用户名和密码错误');
    //     // if (res.code != 200) return this.$message.error('登录错误,联系管理员');
    //     // this.$message.success('登陆成功');
    //     // // 登录成功后 保存token 后面所有的接口都需要token验证
    //     // sessionStorage.token = res.data.token;
    //     // 编程式导航 登录成功 跳转到首页
    //     // this.$router.push({ path: "/home/indexPage" });
    //     // if (valid) {
    //     //     // this.$axios.post("http://.com/login",this.loginForm).then(res => {
    //     //     //     console.log(res);
    //     //     // })
    //     //     // this.axios.post("login", this.loginForm).then(res => {
    //     //     //     console.log(res);
    //     //     //     if (res.data.code == 200) {
    //     //     //         this.$message({
    //     //     //             message: "登陆成功",
    //     //     //             type: 'success'
    //     //     //         })
    //     //     //         // 登录成功后 保存token 后面所有的接口都需要token验证
    //     //     //         sessionStorage.token = res.data.data.token;
    //     //     //         // 编程式导航 登录成功 跳转到首页
    //    this.$router.push({ path: "/home" });
    //     //     //     } else {
    //     //     //         if (res.data.code == 101) {
    //     //     //             this.$message({
    //     //     //                 message: "用户名密码错误",
    //     //     //                 type: 'error'
    //     //     //             })
    //     //     //         }
    //     //     //     }
    //     //     // })

    //     // } else {
    //     //     console.log('error submit!!');
    //     //     return false;
    //     // }
    //     // });
    //     },

    // ShopSessionId接口相当于token
    async getShopSessionId() {
      // 获取前判断本地是否有缓存，如果有直接跳首页
      let old_session = localStorage.getItem("ApShop-Session-Id");
      let sign_login = localStorage.getItem("sign_login");
      if (old_session && sign_login && this.checked2 == true) {
        return this.$router.push("/home/index");
      }
      await apiServe.getShopSessionId().then((res) => {
        if (res.error == 0) {
          localStorage.setItem("ApShop-Session-Id", res.session_id);
          this.getLoginVerify();
          this.getloginShopInit();
          // 注册图片验证码
          this.RegisterCode();
          // 密码登录图形验证码
          this.getNickVerify();
          // 获取扫码状态
          // this.getLoginCodeStatus();
        }
      });
    },
    // 调取登录接口
    async submitForm() {
      // console.log(this.loginForm, "loginForm");
      this.loginForm.username = this.setTimerData.username;
      let res = await apiServe.loginSubmit(this.loginForm);
      // console.log(res, "res数据");
      if (res.error == 0) {
        localStorage.setItem("sign_login", 1);
        // localStorage.setItem("name", res.user.username);
        //只有当选中记住密码才把账号存储起来
        if (this.checked == true) {
          localStorage.setItem("name", res.user.username);
          // console.log(111, '1111');
        } else {
          localStorage.setItem("name", "");
        }
        if (this.checked2 != true) {
          alert("请勾选用户使用协议");
          // this.readFlag = false
        }
        //当点击记住密码后,记住密码单选框变成true,此时把选中的状态传给首页
        this.$router.push("/home/index");
      }

      // else {
      //   this.readFlag = true
      // }
    },

    // 阅读勾选
    Click2() {
      this.checked2 == true;
      console.log(this.checked2 == true, "勾选中了");
    },

    // this.$router.push('/shouye')
    // if(res){
    //    // 存token  存userName 存role
    //   localStorage.setItem('ApShop-Session-Id',res.data.ApShop-Session-Id)s
    //   localStorage.setItem('username',res.data.username)
    //   localStorage.setItem('password',res.data.password)
    //   localStorage.setItem('uniqid',res.data.uniqid)
    //   localStorage.setItem('code',res.data.code)

    //   this.$message({
    //     message:'登录成功',
    //     type:"success"
    //   })
    //     // 跳转  首页
    //     this.$router.push('/home')
    // } else {
    //             if (res.data.code == 404) {
    //                 this.$message({
    //                     message: "用户名密码错误",
    //                     type: 'error'
    //                 })
    //             }
    //         };
    //

    //改变验证码的图片
    changeImg() {
      this.getLoginVerify();
    },
    // 账号密码登录图片二维码点击刷新
    changeAccountImg() {
      this.getNickVerify();
    },
    // 获取登录微信ewm状态1
    async getLogoEwm() {
      this.ewmImgwx = require("@/assets/loading_code.gif");
      this.currCodeType = 1;
      let res = await apiServe.logoewm({
        type: 1,
      });
      this.ewmImgwx = res.url;
      this.appkey = res.key;
      // this.weiMa = true;
      console.log(res, "获取登录ewm状态1，微信小程序");
      if (this.appkey) {
        this.getLoginCodeStatus(0);
        this.lunxun();
      }
    },
    // 获取登录appewm状态2
    async getLogoEwm2() {
      this.currCodeType = 2;
      let res = await apiServe.logoewm({
        type: 2,
      });
      this.ewmImgApp = res.url;
      this.appkey = res.key;
      if (this.appkey) {
        console.log(res, "获取登录ewmApp状态2");
        console.log(this.appkey, "key值");
        this.getQRCode(this.ewmImgApp);
        // this.getLoginCodeStatus(this.appkey);
        this.getLoginCodeStatus(0);
        this.lunxun();
      }
    },
    // 登录获取验证码
    async getLoginVerify() {
      let res = await apiServe.loginVerify();
      // console.log("图片验证码", res);
      //每点击一次验证码,掉一次获取验证码的接口,然后把接口里面的uniqid赋值给setTimerData.uniqid
      this.phoneTimeData.uniqid = res.data.uniqid;
      this.verifyImg = res.data.img;
    },
    // 登录页基础信息
    async getloginShopInit() {
      let res = await apiServe.loginShopInit();
      this.name = res.data.name;
      this.logo = res.data.logo;
      this.check_register = res.data.check_register;
      this.login_show_img = res.data.login_show_img;
      // console.log("登录基础信息", res);
      localStorage.setItem("username", res.data.name);
    },
    // 跳转注册
    register() {
      this.$router.push({
        name: "Register",
        params: { check_register: this.check_register },
      });
      // this.$router.push({path: '/register', params: {check_register: this.check_register}})
    },

    // 获取注册图片验证码
    async RegisterCode() {
      let res = await apiServe.registerVerify();
      console.log(res, "获取注册图片验证码");
      this.setTimerData.uniqid = res.data.uniqid;
      this.verifyImgZhu = res.data.img;
    },
    // 密码登录用图片验证码
    async getNickVerify() {
      let res = await apiServe.NickVerify();
      console.log(res, "密码登录图片验证码");
      // console.log("图片验证码", res);
      //每点击一次验证码,掉一次获取验证码的接口,然后把接口里面的uniqid赋值给setTimerData.uniqid
      this.AccountTimeData.uniqid = res.data.uniqid;
      this.verifyImgAccount = res.data.img;
    },
    // 设置定时器
    // StatusTimer() { },
    getLoginCodeStatus(type) {
      if (type === 1) {
        if (!this.getLoginCodeStatusRes) {
          return;
        }
      }
      this.getLoginCodeStatusRes = false;

      apiServe
        .LoginCodeStatus({
          key: this.appkey,
        })
        .then((res) => {
          if (res.error === 0) {
            this.CodeStatus = res.status;
            // this.CodeStatus = 2;

            console.log(res, "获取扫码状态");
            console.log(this.CodeStatus, "666");
            this.getLoginCodeStatusRes = true;

            if (this.CodeStatus == 2 || this.CodeStatus == 1) {
              this.end_time = res.end_time;
              console.log(this.end_time, "过期时间");
              // 获取当前时间
              const now =
                new Date().getTime() + 8 * 60 * 60 * 1000 + 60 * 1000; //300秒，测试3570秒
              // 格式化时间
              const formattedTime = new Date(now)
                .toISOString()
                .slice(0, 19)
                .replace("T", " ");

              console.log(formattedTime, "当前时间");

              if (formattedTime > this.end_time) {
                clearInterval(this.StatusTimer);
                this.showRefresh = true;
                console.log(formattedTime, "二维码过期了");
              }
            }
            if (this.CodeStatus == 3) {
              clearInterval(this.StatusTimer);
              setTimeout(() => {
                // this.isRotating = false;
                this.$router.push("/home/index");
                console.log("扫码成功");
                // 这里可以添加其他逻辑，比如发起一个刷新请求
              }, 1000); // 假设动画时长为1秒
            }
          } else {
            if(res.error === 810150){
              this.rotate();
            }else {
              clearInterval(this.StatusTimer);
            }

          }
        });
      // console.log(res, '获取扫码状态');
      // console.log(this.CodeStatus, '666');
    },
    lunxun() {
      this.StatusTimer = setInterval(() => {
        this.getLoginCodeStatus(1);
      }, 1600);
    },
  },
  created() {
    // if(localStorage.getItem('sign_login') === 1){
    //     this.$router.push('/home');
    // }
    this.getShopSessionId();
    // this.getLogoEwm();

    // this.getNickVerify();
    // this.RegisterCode();
    // this.getLoginVerify(), this.getloginShopInit();
  },
  mounted() {
    this.logo = " http://assets/00.png";
    if (localStorage.getItem("name")) {
      this.phoneTimeData.username = localStorage.getItem("name");
    }
    if (localStorage.getItem("nameAccount")) {
      this.AccountTimeData.nickname = localStorage.getItem("nameAccount");
    }
  },
};
</script>
<style>
/* @import "@/components/css/big.css"; */
</style>
<!-- <style scoped src="@/components/css/big.css">
@import "@/components/css/big.css";
</style> -->
<style lang="scss" scoped>
//@import "../components/css/big.css";
// @import url('../components/css/big.css');
@media screen and (min-width: 2200px) {
   ::v-deep .el-checkbox__inner{
      width: 16px !important;
      height: 16px !important;
     }
   ::v-deep .el-checkbox__inner::after{
      height: 8px !important;
      width: 4px !important;
     }
  .el-checkbox[data-v-26084dc2] {
    color: #666666;
    font-size: 16px !important;
  }

  ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #666666 !important;
    font-size: 16px !important;
  }

  .cursor {
    cursor: pointer;
  }

  .yiyou {
    line-height: 25px;
    //margin-left: 180px;
    margin: 20px 0 20px 0px;
    font-size: 18px !important;
  }

  .checkzi {
    margin-left: 115px;
  }
  .checkzi1{
    //margin-left: 10px;
    text-align: center;
  }

  .morezi {
    position: absolute;
    top: -12px;
    left: 50%;
    margin-left: -73px;
    background-color: white;
    padding: 0px 25px;
    font-size: 18px;
  }

  .copyright {
    width: calc(33% - 50px);
    position: fixed;
    padding-bottom: 35px;
    bottom: 0;
    text-align: center;
    background-color: #ffffff;
    font-size: 18px;
  }

  .icontu {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .iconSize {
    font-size: 21px;
    margin-bottom: 50px;
  }

  .login-left-icon {
    width: 40px;
    height: 40px;
  }

  .verifyImg {
    width: 100%;
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .box {
    width: 100%;
    position: relative;
    height: 100vh;
    margin: auto;
    display: flex;
    /* 设置背景图片的URL */
    background-size: cover;
    /* 背景图片大小为覆盖整个元素 */
    background-position: center;
    /* 背景图片居中显示 */
    background-repeat: no-repeat;

    /* 背景图片不重复 */
    .main {
      width: 100%;
      height: 100%;

      display: flex;

      .box_left {
        width: 67%;
        height: 100%;
        /* 设置背景图片的URL */
        background-size: contain;
        /* 背景图片大小为覆盖整个元素 */
        /* 背景图片居中显示 */
        background-repeat: no-repeat;
      }

      .box_right {
        width: 33%;
        background: #ffffff;

        display: flex;

        align-items: center;
        /* 垂直居中 */
        justify-content: center;
        /* 水平居中，如需 */

        .right_mian {
          width: 100%;
          margin-bottom: 50px;
        }

        .right_header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            margin-right: 5px;
            height: 40px;
            width: 125px;
          }

          h3 {
            text-align: center;
          }

          .logo {
            width: 240px;
            height: 158px;
          }
        }

        .right_form {
          margin: 0px auto;
          width: 68%;

          min-width: 385px;

          input {
            height: 35px;
            border: none;
            outline: none;
          }

          .phone-password{
            margin-top: 35px;
          }

          .phone {
            display: flex;
            background-color: rgb(83 83 83);
            border-radius: 1px;
            width: 100%;
            height: 76px;
            background: #f5f5f5;
            border-radius: 4px 4px 4px 4px;
            box-shadow: rgb(117 117 117 / 18%) 3px 3px 7px 0px;

            .xtb {
              font-size: 0.7rem;
              font-weight: bolder;
              width: 50px;

              img {
                width: 35px;
                height: 35px;
                color: rgb(124 124 124);
                margin-left: 10px;
                margin-top: 21px;
              }
            }
          }

          .phone>img {
            width: 20px;
            height: 20px;
            /* margin-left: 10px; */
            margin-right: 10px;
          }

          .phone>input {
            width: 80%;
            height: 100%;
            background-color: #f5f5f5;
            font-size: 24px;
            color: #666666;
          }

          .yan {
            display: flex;
            align-items: center;
            background-color: #f5f5f5;
            margin-top: 35px;
            height: 76px;
            border-radius: 4px;
            box-shadow: rgba(117, 117, 117, 0.18) 3px 3px 7px 0px;

            .dxtb {
              font-size: 0.7rem;
              font-weight: bolder;
              width: 50px;

              img {
                width: 35px;
                height: 35px;
                color: rgb(124 124 124);
                margin-left: 10px;
                margin-top: 1px;
              }
            }

            input {
              width: 60%;
              background-color: #f5f5f5;
              height: 100%;
              color: #666666;
              font-size: 24px;
            }

            .yzm {
              font-size: 0.7rem;
              font-weight: bolder;
              width: 50px;

              img {
                width: 35px;
                height: 35px;
                color: rgb(124 124 124);
                margin-left: 10px;
                margin-top: 1px;
              }
            }
          }

          .yan>img {
            width: 20px;
            height: 20px;
            /* margin-left: 10px; */
            margin-right: 10px;
          }

          .ycode {
            background-color: #f8f8f8;
            border: none;
            width: 30%;
            height: 45px;
            display: inline-block;
            padding: 3px;
            font-size: 0.6rem;
            font-weight: 500;

            font-size: 12px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          .picture {
            background-color: #ffffff;
            padding: 0;
            color: #ff9e00;
            font-size: 24px;
            height: 65px;
          }
        }
      }
    }

    .btn {
      background-color: #4a89ff;
      color: #fff;
      width: 100%;
      font-size: 28px;
      height: 76px;
      margin: 5px 0 30px 0;
      font-weight: 500;
    }

    .btn:hover,
    .btn:focus {
      background-color: #4a89ff;
      color: #fff;
      width: 100%;
      font-size: 28px;
      height: 76px;
      margin: 5px 0 30px 0;
    }
  }

  .el-button {}

  ::v-deep input::-webkit-input-placeholder {
    color: rgb(155, 153, 153) !important;
  }

  ::v-deep input::-moz-input-placeholder {
    color: rgb(155, 153, 153) !important;
  }

  ::v-deep input::-ms-input-placeholder {
    color: rgb(155, 153, 153) !important;
  }

  .forget {
    cursor: pointer;
    color: #666666;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
  }

  .codeImg {
    position: relative;
    width: 278px;
    height: 298px;
    cursor: pointer;
    text-align: center;
    background-color: white;
    margin: auto;

    img {
      width: 80%;
      height: 215px;
      margin-top: 18px;
    }

    h3 {
      display: block;
      position: absolute;
      top: -45px;
      right: -20px;
      color: #fff;
    }
  }

  /* ::v-deep.el-checkbox__label {
      font-size: 18px !important;
    } */

  .el-checkbox {
    color: #fff !important;
  }

  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    /* background-color: #4a89ff !important;
      border-color: #4a89ff !important; */
  }

  .sanjiao {
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-left: 7px solid rgb(119 119 119 / 22%);
    border-bottom: 4px solid transparent;
    border-top: 5px solid transparent;
    margin-right: 32px;
    float: right;
    margin-right: -22px;
    margin-top: 3px;
    font-size: 12px;
  }

  .sjhdl {
    height: 39px;
    font-weight: 550;
    font-size: 38px;
    color: #333333;
    line-height: 39px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 30px 0;
    padding-bottom: 30px;
  }


  .check2 {
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    line-height: 21px;
    text-align: left;
    margin: 10px 0;

    span {
      color: #0059ff;
    }
  }
  .check21{
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    line-height: 21px;
    margin: 10px 0;

    span {
      color: #0059ff;
    }
  }

  .more {
    span {
      color: #999999;
    }

    margin: 55px 0;
    border-top: 0.3px solid #999999;
    position: relative;
  }


  /* ::v-deep .el-checkbox__label {
      color: #4a89ff !important;
      font-size: 18px !important;
    }   */
  .el-checkbox__label {
    display: inline-block;
    padding-left: 10px;
    line-height: 19px;
    font-size: 18px !important;
  }

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #666666 !important;
  }

  .codeImg {
    position: relative;
  }

  .codeImg>div {
    position: absolute;
  }

  .sao {
    width: 278px;
    height: 278px;
    background: #f1f1f1;
    opacity: 0.94;
    position: relative;
    top: 0px;
    right: 0px;
  }

  .codeImg>img {
    width: 228px;
    height: 228px;
    margin: 20px 0;
  }

  .appcode {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d7d7d7;
    clip-path: polygon(0 0, 100% 0, 0% 100%);
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);

    span {
      position: absolute;
      left: 30px;
      top: 55px;
      font-size: 25px;
    }
  }

  .weicode {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #a5a5a5;
    clip-path: polygon(0 0, 100% 0, 0% 100%);
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);

    span {
      transform: rotate(-180deg);
      position: absolute;
      top: 45px;
      left: 25px;
      font-size: 25px;
    }
  }

  .saoma {

    text-align: center;
  }

  .sao>div {
    cursor: pointer;
  }

  .saoma img {}

  .sjhd2 {

    margin: 40px;
  }

  .codeIMG {
    position: relative;
    top: 10px;
    height: 279px !important;
    width: 279px !important;
  }

  .appimg {
    top: -10px;
  }

  .titleClose {
    margin-left: 0;
    margin-top: -22px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
  }

  .bj {
    background-image: url("../assets/code.jpg");
    background-size: 100%;
    width: 278px;
    height: 278px;
    position: relative;
    margin-top: 10px;
  }

  .ScannedCode {
    text-align: center;
    margin: auto;
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  .opacitybj {
    position: absolute;

    background: #d9d9d9e3;
    width: 279px;
    height: 279px;
    top: 10px;
    /*right: -5px;*/

    img {
      position: absolute;
      width: 60px;
      height: 60px;

      top: -90px;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }

    div {
      position: absolute;
      top: 160px;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      font-size: 16px;
      color: #333333;
    }
  }

  .rotate {
    transition: transform 0.5s ease-in-out;
    /* 平滑过渡效果 */
    transform: rotate(360deg);
    /* 旋转360度 */
  }

  .login-left-icon {
    width: 38px;
    height: 38px;
  }

  .el-checkbox__inner::after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #FFFFFF;
    border-left: 0;
    border-top: 0;
    height: 10px !important;
    left: 7px !important;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(0);
    width: 5px !important;
    transition: transform 0.15s ease-in 0.05s;
    transform-origin: center;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    /* background-color: #4a89ff !important;
        border-color: #4a89ff !important; */
    width: 23px !important;
    height: 22px !important;
  }

  .el-checkbox__inner {
    display: inline-block;
    position: relative;
    border: 1px solid #DCDFE6;
    border-radius: 2px;
    box-sizing: border-box;
    width: 23px !important;
    height: 22px !important;
    background-color: #FFFFFF;
    z-index: 1;
    transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
  }

  .el-divider__text {
    font-size: 18px !important;
  }

  .el-divider--horizontal {

    margin: 45px 0 !important;
  }

  /* ::v-deep.el-button:hover,.el-button:focus {
        color: #4A89ff;
        border-color: #4A89ff !important;
        background-color: #4A89ff !important;
    } */
}

@media screen and (max-width: 2199px) {
  .cursor {
    cursor: pointer;
  }

  .checkzi {
    margin-left: 90px
  }
  .checkzi1{
    //margin-left: 10px;
    text-align: center;
  }

  .yiyou {
    line-height: 25px;
    //margin-left: 180px;
    margin: 20px 0 20px 0px;
  }

  .morezi {
    position: absolute;
    top: -12px;
    left: 50%;
    background-color: white;
    margin-left: -79px;
    padding: 0px 25px;
    font-size: 16px;
  }

  .copyright {
    width: calc(33% - 50px);
    position: fixed;
    padding-bottom: 15px;
    bottom: 0;
    text-align: center;
    background-color: #ffffff;
    font-size: 16px;
  }

  .verifyImg {
    width: 100%;
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .box {
    width: 100%;
    // background-color: #f2f6e8;
    position: relative;
    height: 100vh;
    margin: auto;
    display: flex;
    // justify-content: right !important;
    // align-items: center;
    /* 设置背景图片的URL */
    background-size: cover;
    /* 背景图片大小为覆盖整个元素 */
    background-position: center;
    /* 背景图片居中显示 */
    background-repeat: no-repeat;

    /* 背景图片不重复 */
    .main {
      // width: 360px;
      // height: 430px;
      width: 100%;
      height: 100%;

      display: flex;
      // position: fixed;
      // right: 15%;
      // top: 20%;

      .box_left {
        width: 67%;
        // width: 1280px;
        height: 100%;
        // background-color: white;
        // background-image: url("../assets/login.png");
        /* 设置背景图片的URL */
        background-size: contain;
        /* 背景图片大小为覆盖整个元素 */
        // background-position: center;
        /* 背景图片居中显示 */
        background-repeat: no-repeat;
      }

      .box_right {
        width: 33%;
        // width: 640px;
        height: 100%;
        background: #ffffff;
        //position: relative;
        // width: 100%;
        // height: 100%;
        // background-color: #535353b5;
        // box-shadow: inset 0px 0px 3px 2px rgb(219 215 215 / 18%);
        display: flex;

        align-items: center;
        /* 垂直居中 */
        justify-content: center;
        /* 水平居中，如需 */

        overflow-y: auto;
        // align-items: center;
        // justify-content: center;
        // border-radius: 5px;

        .right_mian {
          width: 100%;
          //height: 90%;
          // background-color: aqua;
          margin-bottom: 50px;
        }

        // 手机号登录
        .right_header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          //margin: 12% 0 0 0;

          img {
            margin-right: 5px;
            height: 40px;
            width: 125px;
          }

          h3 {
            //margin-right: 25px;
            text-align: center;
          }

          .logo {
            width: 188px;
            height: 120px;
            //background-color: aliceblue;
          }
        }

        .right_form {
          margin: 0px auto;
          // padding: 20px;
          width: 68%;

          min-width: 385px;

          input {
            height: 35px;
            border: none;
            outline: none;
            // padding: 5px 10px;
          }

          .phone-password{
            margin-top: 25px;
          }

          .phone {
            display: flex;
            background-color: rgb(83 83 83);
            // border: 1px solid #535353b5;
            border-radius: 1px;

            width: 100%;
            height: 56px;
            background: #f5f5f5;
            border-radius: 4px 4px 4px 4px;
            box-shadow: rgb(117 117 117 / 18%) 3px 3px 7px 0px;

            .xtb {
              font-size: 0.7rem;
              font-weight: bolder;
              width: 40px;

              img {
                width: 25px;
                height: 25px;
                color: rgb(124 124 124);
                margin-left: 10px;
                margin-top: 15px;
              }
            }
          }

          .phone>img {
            width: 20px;
            height: 20px;
            /* margin-left: 10px; */
            margin-right: 10px;
          }

          .phone>input {
            width: 80%;
            height: 100%;
            background-color: #f5f5f5;
            font-size: 18px;
            // background-color: #535353b5;
            color: #666666;
          }

          .yan {
            display: flex;
            align-items: center;
            background-color: #f5f5f5;
            margin-top: 25px;
            //  width: 100%;
            height: 56px;
            // border-top-right-radius: 4px;
            // border-bottom-right-radius: 4px;
            border-radius: 4px;
            box-shadow: rgba(117, 117, 117, 0.18) 3px 3px 7px 0px;

            .dxtb {
              font-size: 0.7rem;
              font-weight: bolder;
              width: 40px;

              img {
                width: 25px;
                height: 25px;
                color: rgb(124 124 124);
                margin-left: 10px;
                margin-top: 1px;
              }
            }

            input {
              width: 60%;
              //font-size: 0.7rem;
              background-color: #f5f5f5;
              height: 100%;
              color: #666666;
              font-size: 18px;
            }

            .yzm {
              font-size: 0.7rem;
              font-weight: bolder;
              width: 40px;

              img {
                width: 25px;
                height: 25px;
                color: rgb(124 124 124);
                margin-left: 10px;
                margin-top: 1px;
              }
            }
          }

          .yan>img {
            width: 20px;
            height: 20px;
            /* margin-left: 10px; */
            margin-right: 10px;
          }

          .ycode {
            background-color: #f8f8f8;
            border: none;
            width: 30%;
            height: 45px;
            display: inline-block;
            padding: 3px;
            font-size: 0.6rem;
            // color: #257FA2;
            font-weight: 500;

            font-size: 12px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          .picture {
            background-color: #ffffff;
            // height: 100%;
            // width: 50%;
            padding: 0;
            color: #ff9e00;
            font-size: 15px;
          }
        }
      }
    }

    // 按钮
    .btn {
      background-color: #4a89ff;
      color: #fff;
      width: 100%;
      font-size: 22px;
      height: 56px;
      margin: 5px 0 15px 0;
    }
  }

  .el-button {
    // border: 1px solid #fd9e04;
  }

  ::v-deep input::-webkit-input-placeholder {
    color: rgb(155, 153, 153) !important;
  }

  ::v-deep input::-moz-input-placeholder {
    color: rgb(155, 153, 153) !important;
  }

  ::v-deep input::-ms-input-placeholder {
    color: rgb(155, 153, 153) !important;
  }

  .forget {
    cursor: pointer;
    color: #666666;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    // padding: 15px;
    margin: 15px 0;
  }

  .codeImg {
    position: relative;
    width: 228px;
    height: 245px;
    // background-color: aqua;
    cursor: pointer;
    text-align: center;
    background-color: white;
    margin: auto;

    img {
      width: 80%;
      // vertical-align: middle;
      height: 215px;
      margin-top: 18px;
      // position: absolute;
    }

    h3 {
      display: block;
      position: absolute;
      top: -45px;
      right: -20px;
      color: #fff;
    }
  }

  ::v-deep .el-checkbox__label {
    font-size: 12px;
  }

  .el-checkbox {
    color: #fff;
  }

  // ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
  //   color: #ffffff !important;
  // }

  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #4a89ff !important;
    border-color: #4a89ff !important;
  }

  // 三角
  .sanjiao {
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-left: 7px solid rgb(119 119 119 / 22%);
    // border-l: 11px solid black;
    border-bottom: 4px solid transparent;
    border-top: 5px solid transparent;
    margin-right: 32px;
    float: right;
    margin-right: -22px;
    margin-top: 3px;
    font-size: 12px;
  }

  // 手机号登录
  .sjhdl {
    //width: 150px;
    height: 39px;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 30px;
    color: #333333;
    line-height: 39px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin: 30px 0;
  }

  // 勾选已阅读并接受
  .check2 {
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 21px;
    text-align: left;
    margin: 20px 0;

    span {
      color: #0059ff;
    }
  }
  .check21{
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    line-height: 21px;
    margin: 10px 0;

    span {
      color: #0059ff;
    }
  }

  .el-divider--horizontal {
    margin: 35px 0;
  }

  .more {
    span {
      color: #999999;
    }

    margin: 40px 0;
    border-top: 0.3px solid #999999;
    position: relative;
  }

  ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #666666;
    font-size: 16px;
  }

  ::v-deep .el-checkbox__label {
    // font-size: 12px;
    color: #666666;
    font-size: 16px;
  }

  .codeImg {
    position: relative;
  }

  .codeImg>div {
    position: absolute;
    // width: 100px;
    // height: 100px;
  }

  .sao {
    width: 228px;
    height: 228px;
    background: #f1f1f1;
    opacity: 0.94;
    position: relative;
    top: 0px;
    right: 0px;
  }

  // .codeImg>div:nth-child(2) {
  //   top: 0;
  //   left: 0;

  // }

  .codeImg>img {
    width: 228px;
    height: 228px;
    margin: 20px 0;
  }

  .appcode {
    position: absolute;
    // top: 50px;
    // left: -30px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d7d7d7;
    clip-path: polygon(0 0, 100% 0, 0% 100%);
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);

    span {
      position: absolute;
      // left: 0;
      // top: 0;
      left: 30px;
      top: 40px;
      font-size: 18px;
    }
  }

  .weicode {
    position: absolute;
    // bottom: -130px;
    // right: -30px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #a5a5a5;
    clip-path: polygon(0 0, 100% 0, 0% 100%);
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);

    span {
      transform: rotate(-180deg);
      position: absolute;
      top: 45px;
      left: 25px;
      font-size: 18px;
    }
  }

  .saoma {
    // background-color: #0059ff;
    text-align: center;
  }

  .sao>div {
    cursor: pointer;
  }

  .saoma img {}

  .sjhd2 {
    // background-color: red;
    margin: 40px;
  }

  .codeIMG {
    position: relative;
    top: 10px;
    // z-index: 999;
  }

  .appimg {
    top: -10px;
  }

  .titleClose {
    // background-color: red;
    margin-left: 0;
    margin-top: -22px;
    width: 228px;
    display: flex;
    justify-content: space-between;
    // font-size: 18px;
  }

  .bj {
    background-image: url("../assets/code.jpg");
    background-size: 100%;
    width: 228px;
    height: 228px;
    position: relative;
    margin-top: 10px;
  }

  // 已扫码
  .ScannedCode {
    text-align: center;
    margin: auto;
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  // 失效码背景
  .opacitybj {
    position: absolute;
    // opacity: 0.88;
    background: #d9d9d9e3;
    width: 228px;
    height: 228px;
    top: 10px;
    //right: -5px;

    img {
      position: absolute;
      width: 60px;
      height: 60px;
      // top: 20px;
      // left: 67px;
      top: -60px;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }

    div {
      position: absolute;
      top: 130px;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      font-size: 14px;
      color: #333333;
    }
  }

  // 刷新按钮旋转动画
  .rotate {
    transition: transform 0.5s ease-in-out;
    /* 平滑过渡效果 */
    transform: rotate(360deg);
    /* 旋转360度 */
  }

  .login-left-icon {
    width: 28px;
    height: 28px;
  }

  .el-divider__text {
    font-size: 16px !important;
  }
}
</style>