<template>
  <div class="box">
    <div class="header">
      <div style="text-align: left; display: flex; height: 100%">
        <img src="../assets/logo-03.png" alt="" style="width: 80px" />
        <div style="margin-left: 10px; font-weight: 700">| 商户注册</div>
      </div>
      <!-- 头部右侧 -->
      <div style="display: flex">
        <div style="margin-right: 50px;">客服热线：400-100-2727（工作日8:00-17:30）</div>

        <div>
          已有账号？ <span style="color: #FD9E03; font-weight: 600; cursor: pointer;" @click="GoBack()">立即登录</span> &nbsp; |
          &nbsp; <span style="color: #FD9E03; font-weight: 600; cursor: pointer;" @click="GoBack()">返回</span>
        </div>
      </div>
    </div>
    <div class="main_box">
      <!-- 步骤条 -->
      <div class="content_top">
        <el-steps  align-center :active="active">
          <el-step title="填写账号信息"></el-step>
          <el-step title="填写企业信息"></el-step>
          <el-step title="提交成功"></el-step>
          <!-- <el-step title="交易完成" ></el-step> -->
        </el-steps>
        <!-- <el-steps align-center >
        <el-step title="订单创建" ></el-step>
        <el-step title="售后"></el-step>
      </el-steps> -->
      </div>

      <!-- <div class="content_top">
      <el-steps :active="tableData.status + 1" align-center v-if="tableData.status > -1" finish-status="success">
        <el-step title="买家下单" :description="tableData.create_time"></el-step>
        <el-step title="买家付款" :description="tableData.pay_time"></el-step>
        <el-step title="卖家发货" :description="tableData.send_time"></el-step>
        <el-step title="交易完成" :description="tableData.receive_time"></el-step>
      </el-steps>
      <el-steps align-center :active="tableData.status + 2" v-else finish-status="success">
        <el-step title="订单创建" :description="tableData.create_time"></el-step>
        <el-step title="售后"></el-step>
      </el-steps>
    </div> -->

      <el-form :model="SyssetData" ref="ruleForm" label-width="195px" class="demo-ruleForm" :rules="rules">
        <div v-if="this.active == 1">
          <el-form-item label="负责人姓名" prop="head_name">
            <el-input v-model="SyssetData.head_name" placeholder="请输入负责人姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="head_phone">
            <el-input v-model="SyssetData.head_phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="e_mail">
            <el-input v-model="SyssetData.e_mail" placeholder="请输入邮箱"></el-input>
          </el-form-item>
          <el-form-item label="所在区域" prop="area">
            <el-cascader v-model="areaShow" :props="{ value: 'id', label: 'name', children: 'child' }"
              :options="address" @change="handleChange" clearable>
            </el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="address_info">
            <el-input v-model="SyssetData.address_info" placeholder="请输入详细地址"></el-input>
          </el-form-item>
        </div>
        <div v-if="active == 2">
          <el-form-item label="企业名称" prop="business_name" style="margin-left: 10px;">
            <el-input v-model="SyssetData.business_name" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="企业logo" prop="logo_img">
            <div class="block" style="
              display: inline-block;
              vertical-align: middle;
              position: relative;
            " @mouseover="showInfoIndex = 1" @mouseleave="showInfoIndex = 0">
              <el-button type="text" v-if="!this.SyssetData.logo_img" @click="submitUpload(1)"
                style="border: 1px #ccc dotted; width: 140px; height: 140px">
                <i style="font-size: 30px" class="el-icon-plus avatar-uploader-icon" />
                <div style="color: #ccc; margin-top: 10px">添加图片</div>
              </el-button>
              <div v-else style="width: 100%; height: 100%">
                <el-image :src="this.SyssetData.logo_img" style="width: 100%; height: 100%">
                </el-image>

                <div v-if="showInfoIndex === 1" class="image-info" @click="submitUpload(1)">
                  替换
                </div>

                <div v-if="showInfoIndex === 1" class="image_delete" @click="delete_image(1)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="申请商家类型" prop="type" show-overflow-tooltip style="margin-left: 10px;">
            <el-select v-model="SyssetData.type" placeholder="请选择商家类型">
              <el-option label="专用车配件商家" :value="1"></el-option>
              <el-option label="小车配件商家" :value="2"></el-option>
              <el-option label="汽车维修商家" :value="3"></el-option>
              <el-option label="汽车美容商家" :value="4"></el-option>
              <el-option label="二手车商家" :value="5"></el-option>
              <el-option label="新车商家" :value="6"></el-option>
              <el-option label="车辆租赁" :value="7"></el-option>
              <el-option label="车辆保险" :value="8"></el-option>
              <el-option label="机械设备" :value="9"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="营业执照" prop="business_license">
            <div class="block" style="
              display: inline-block;
              vertical-align: middle;
              position: relative;
            " @mouseover="showInfoIndex = 2" @mouseleave="showInfoIndex = 3">
              <el-button type="text" v-if="!SyssetData.business_license" @click="submitUpload(0)"
                style="border: 1px #ccc dotted; width: 140px; height: 140px">
                <i style="font-size: 30px" class="el-icon-plus avatar-uploader-icon" />
                <div style="color: #ccc; margin-top: 10px">添加图片</div>
              </el-button>
              <div v-else style="width: 100%; height: 100%">
                <el-image :src="SyssetData.business_license" style="width: 100%; height: 100%">
                </el-image>
                <div v-if="showInfoIndex === 2" class="image-info" @click="submitUpload(0)">
                  替换
                </div>
                <div v-if="showInfoIndex === 2" class="image_delete" @click="delete_image(0)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </el-form-item>

        </div>
        <div v-if="active == 3" style="margin:95px auto auto auto; width: 635px; text-align: center;">
          <img src="../assets/verify.png" style="width: 65px;" alt="">
          <div style="color: #FD9E03; margin: 10px 0;">提交成功，等待审核</div>
          <div style="color: #ccc; font-size: 12px;">请耐心等待短信通知，如有问题请联系客服</div>
        </div>
        <el-form-item>
          <el-button class="btn" @click="nextStep('ruleForm')" v-if="active == 1" >下一步</el-button>
          <el-button class="btn" @click="edit('ruleForm')" v-if="active == 2" >提交申请</el-button>
          <!-- <el-button type="primary"  @click="edit('SyssetData')">下一步</el-button> -->
          <!-- <el-button @click="GoBack()">返回</el-button> -->
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" @click="edit('SyssetData')">提交</el-button>
          <el-button @click="GoBack()">返回</el-button>
        </el-form-item> -->
      </el-form>
      <el-upload action="" ref="register_upload_att" :before-upload="beforeAttUpload" :on-change="onChangeAttImg"
        :on-success="handleAttSuccess" :file-list="[]" :auto-upload="false" class="upload-att-content">
        <el-button slot="trigger" size="small" ref="register_upload_att_button" type="primary">选取文件</el-button>
      </el-upload>

      <!-- 提交弹框 -->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="remove">
        <span style="font-size: 14px">确定要提交注册内容？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="remove()">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { apiServe } from "@/api/api";

export default {
  data() {
    return {
      // 注册信息
      SyssetData: {
        business_name: "",
        type: 1,
        head_name: "",
        head_phone: "",
        e_mail: "",
        area: "",
        address_info: "",
        logo_img: "",
        business_license: "",
        lat: "",
        lng: "",
        describe: "",
      },
      active: 1,
      rules: {
        business_name: [
          { required: true, message: "企业名称不能为空", trigger: "blur" },
        ],
        // status: [{ required: true, message: "状态不能为空", trigger: "blur" }],
        head_name: [
          { required: true, message: "负责人姓名不能为空", trigger: "blur" },
        ],
        // type:[],
        // e_mail:[],
        // area:[],
        // address_info:[],
        // logo_img:[],
        head_phone: [
          {
            required: true,
            message: "负责人手机号码不能为空",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        business_license: [
          { required: true, message: "营业执照不能为空", trigger: "blur" },
        ],
        // describe:[],
      },

      showInfoIndex: 0,
      attDialog: false,
      dialogVisible: false,
      address: [],
      areaShow: "",
      img_type: 0,
    };
  },
  methods: {
    nextStep(formName) {
      this.$refs[formName].validate((valid) => {
          if (!valid) {
            this.$message.error("请填写必填信息!");
            
          }else{
            this.active += 1
          }
        });

      // if(this.active == 3){
      //   this.btns = '提交'
      // }
    },

    // 获取地址基础设置
    async getAddress() {
      await apiServe.get("merchantAccount/register/getAddress").then((res) => {
        if (res.error == 0) {
          this.address = res.list;
        }
      });
    },
    // 关闭
    handleClose(done) {
      done();
    },
    submitUpload(type) {
      this.img_type = type;
      this.$refs.register_upload_att_button.$el.click();
    },
    beforeAttUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isJPG && isLt10M;
    },
    handleAttSuccess() { },
    onChangeAttImg(file) {
      file.image = file.raw;
      delete file.raw;
      this.att_upimg(file);
    },
    async att_upimg(file) {
      await apiServe
        .postImg("merchantAccount/register/uploadImg", file)
        .then((res) => {
          if (res.error == 0) {
            console.log("res.img", this.img_type, res.path);
            if (this.img_type === 1) {
              this.SyssetData.logo_img = res.path;
            } else if (this.img_type === 0) {
              this.SyssetData.business_license = res.path;
            }

            this.$message({
              message: "上传图片成功！",
              type: "success",
            });
            this.$refs.register_upload_att.clearFiles();
          }
        });
    },

    // 删除图片
    delete_image(type) {
      this.$confirm("确定删除图片吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type === 1) {
            this.SyssetData.logo_img = "";
          } else if (type === 0) {
            this.SyssetData.business_license = "";
          }
        })
        .catch(() => {
          this.$message({
            message: "已取消删除操作",
            type: "info",
          });
        });
    },

    // 地区选择
    handleChange(value) {
      console.log("eeeeee", value);
      let area = "";
      let lat = "";
      let lng = "";
      if (value.length === 3) {
        for (const areaKey in this.address) {
          if (this.address[areaKey].id === value[0]) {
            area = this.address[areaKey].name;
            for (const areaChildKey in this.address[areaKey].child) {
              if (this.address[areaKey].child[areaChildKey].id === value[1]) {
                area =
                  area + " " + this.address[areaKey].child[areaChildKey].name;
                for (const areaFinalKey in this.address[areaKey].child[
                  areaChildKey
                ].child) {
                  if (
                    this.address[areaKey].child[areaChildKey].child[
                      areaFinalKey
                    ].id === value[2]
                  ) {
                    area =
                      area +
                      " " +
                      this.address[areaKey].child[areaChildKey].child[
                        areaFinalKey
                      ].name;
                    let location =
                      this.address[areaKey].child[areaChildKey].child[
                        areaFinalKey
                      ].location.split(",");
                    if (location.length === 2) {
                      lat = location[1];
                      lng = location[0];
                    }
                    break;
                  }
                }
                break;
              }
            }
            break;
          }
        }
        console.log(area, "areaarea");
      }
      this.SyssetData.area = area;
      this.SyssetData.lat = lat;
      this.SyssetData.lng = lng;
      console.log(this.SyssetData, "this.SyssetData");
    },
    // 提交提示按钮
    edit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogVisible = true;
        } else {
          return false;
        }
      });
    },

    // 弹出框取消按钮
    remove() {
      this.dialogVisible = false;
      this.$notify.info({
        title: "提示",
        message: "已取消",
      });
    },

    // 提交按钮
    async submit() {
      this.active += 1
      let res = await apiServe.post("merchantAccount/register/submitRegister", {
        logo_img: this.SyssetData.logo_img,
        business_name: this.SyssetData.business_name,
        type: this.SyssetData.type,
        head_name: this.SyssetData.head_name,
        head_phone: this.SyssetData.head_phone,
        e_mail: this.SyssetData.e_mail,
        area: this.SyssetData.area,
        address_info: this.SyssetData.address_info,
        business_license: this.SyssetData.business_license,
        lat: this.SyssetData.lat,
        lng: this.SyssetData.lng,
        describe: this.SyssetData.describe,
      });

      if (res.error == 0) {
        // 写入本地参数
        let num = this.$route.params.check_register ?? "20240909";
        localStorage.setItem("check-register", num);
        this.$notify({
          title: "成功",
          message: "提交成功,审核结果将会以短信方式进行通知！",
          type: "success",
        });
        // if (this.$lastRouteSign && this.$lastRouteSign.path === "/login") {
        //   this.$router.go(-1);
        //   console.log("返回上一级");
        // } else {
        //   // console.log("直接跳转");
        //   // this.$router.push({ path: "/login" });

        // }
      } else {
        this.$notify.error({
          title: "错误",
          message: "提交失败",
        });
      }
      this.dialogVisible = false;
    },
    // 返回上一级
    GoBack() {
      if (this.$lastRouteSign && this.$lastRouteSign.path === "/login") {
        this.$router.go(-1);
        console.log("返回上一级");
      } else {
        console.log("直接跳转");
        this.$router.push({ path: "/login" });
      }
    },
  },
  created() {
    let check_sign = localStorage.getItem("check-register");
    // 获取本地是否有缓存且一致
    // 如果标签不存在则报错
    console.log(this.$route.params, "this.$route.params");
    if (!this.$route.params.check_register) {
      this.$notify.error({
        title: "提示",
        message: "系统繁忙，请从登录页重新进入！",
      });
      return this.GoBack();
    } else if (check_sign && this.$route.params.check_register === check_sign) {
      this.$notify.error({
        title: "提示",
        message: "您已进行过商户注册，请耐心等待短信通知！",
      });
      return this.GoBack();
    } else {
      this.getAddress();
    }
  },
};
</script>
<style lang="scss" scoped>
.upload-att-content {
  display: none;
}

/*标题头部*/
.header {
  // background-color: #f8f8f9;
  padding: 10px 35px;
  margin: 0 0 25px 0;
  line-height: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;

  div {
    font-size: 14px;
    color: #515a6e;
    line-height: 27px;
  }
}

.box {
  height: 100vh;
  // overflow: auto;
}

.main_box {
  margin-left: 100px;
  width: 500px;
  min-height: calc(100vh - 75px);
  margin: auto;
}

// 改变状态按钮
//::v-deep .el-switch__core {
//  width: 52px !important;
//  height: 24px;
//  border-radius: 100px;
//  border: none;
//}
//
//::v-deep .el-switch__core::after {
//  width: 20px;
//  height: 20px;
//  top: 2px;
//}
//
//::v-deep .el-switch.is-checked .el-switch__core::after {
//  margin-left: -21px;
//}
//
///*关闭时文字位置设置*/
//::v-deep .el-switch__label--right {
//  position: absolute;
//  z-index: 1;
//  right: 6px;
//  margin-left: 0px;
//  color: rgba(255, 255, 255, 0.9019607843137255);
//
//  span {
//    font-size: 12px;
//  }
//}

/* 激活时另一个文字消失 */
//::v-deep .el-switch__label.is-active {
//  display: none;
//}
//
///*开启时文字位置设置*/
//::v-deep .el-switch__label--left {
//  position: absolute;
//  z-index: 1;
//  left: 5px;
//  margin-right: 0px;
//  color: rgba(255, 255, 255, 0.9019607843137255);
//
//  span {
//    font-size: 12px;
//  }
//}

.el-switch {
  height: 25px;
}

.image_delete {
  display: block;
  position: absolute;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  right: 0;
  top: 0;
  width: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 0 0 0 2px;
  cursor: pointer;
}

.block {
  position: relative;
  width: 140px;
  height: 140px;
  margin-left: 10px;
}

.image-info {
  display: block;
  position: absolute;
  font-size: 13px;
  height: 20px;
  line-height: 20px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 1px 0 0 2px;
  cursor: pointer;
}

.el-form-item {
  margin-bottom: 18px;
}

// 下一步按钮
// 按钮
.btn {
  background-color: #fd9e04;
  color: #fff;
  width: 100%;
  font-size: 0.9rem;
  margin-top: 20px;
}

::v-deep .el-cascader .el-input {
  width: 305px;
}

.btn:hover {
  background-color: #fd9e04dd;
  color: #ffffff;
}

.content_top {
  margin-bottom: 30px;
}

.el-steps--horizontal {
  padding-left: 30px;
  width: 585px;
}

::v-deep .el-step__head.is-finish{
  color: #FAA102;
  border-color: #FAA102;
}
::v-deep .el-step__title.is-finish {
  color: #FAA102;
}
</style>